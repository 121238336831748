import { createReducer } from "@reduxjs/toolkit";
import * as actions from "./actions";

const initialState = {
  assets: {},
  assetsMini: {},
  params: {
    page: 0,
    pageSize: 20,
    search: "",
    ordering: "",
    filters: ""
  },
  assetDetails: null,
  filtersList: [],
  assetDataList: {},
  assetDataListType: [],
  groupModal: { show: false, data: [] },
  visualDashboardData: {}
};

const reducer = createReducer(
  initialState,
  {
    [actions.getAssetsSuccess.type]: (state, action) => ({
      ...state,
      assets: action.payload
    }),
    [actions.actionGroupModal.type]: (state, action) => ({
      ...state,
      groupModal: action.payload
    }),
    [actions.setAssetsParams.type]: (state, action) => ({
      ...state,
      params: {
        ...state.params,
        ...action.payload
      }
    }),
    [actions.getAssetsMiniSuccess.type]: (state, action) => ({
      ...state,
      assetsMini: action.payload
    }),
    [actions.getAsset.type]: state => ({
      ...state,
      assetsMini: null
    }),
    [actions.getAssetDataListTypesSuccess.type]: (state, action) => ({
      ...state,
      assetDataList: action.payload
    }),
    [actions.getAssetDataListTypeSuccess.type]: (state, action) => ({
      ...state,
      assetDataListType: action.payload
    }),
    [actions.getAssetSuccess.type]: (state, action) => ({
      ...state,
      assetDetails: action.payload
    }),
    [actions.getAssetFilterChoicesSuccess.type]: (state, action) => ({
      ...state,
      filtersList: action.payload
    }),
    [actions.getChartsDataSuccess.type]: (state, action) => ({
      ...state,
      visualDashboardData: action.payload
    })
  },
  [], // required
  state => state // required
);

export default reducer;
