import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

export const setTargetsParams = createAction(constant.SET_PARAMS_TARGETS);

// Get Targets
export const getTargets = createAction(constant.GET_TARGETS);
export const getTargetsSuccess = createAction(constant.GET_TARGETS_SUCCESS);
export const getTargetsFailed = createAction(constant.GET_TARGETS_FAILED);

// Get Targets Mini
export const getTargetsMini = createAction(constant.GET_TARGETS_MINI);
export const getTargetsMiniSuccess = createAction(constant.GET_TARGETS_MINI_SUCCESS);
export const getTargetsMiniFailed = createAction(constant.GET_TARGETS_MINI_FAILED);

// Get Target
export const getTarget = createAction(constant.GET_TARGET);
export const getTargetSuccess = createAction(constant.GET_TARGET_SUCCESS);
export const getTargetFailed = createAction(constant.GET_TARGET_FAILED);

// Get Target Types
export const getTargetTypes = createAction(constant.GET_TARGET_TYPES);
export const getTargetTypesSuccess = createAction(constant.GET_TARGET_TYPES_SUCCESS);
export const getTargetTypesFailed = createAction(constant.GET_TARGET_TYPES_FAILED);

// Get Target Tags
export const getTargetTags = createAction(constant.GET_TARGET_TAGS);
export const getTargetTagsSuccess = createAction(constant.GET_TARGET_TAGS_SUCCESS);
export const getTargetTagsFailed = createAction(constant.GET_TARGET_TAGS_FAILED);

// Get Schedule Data
export const getScheduleData = createAction(constant.GET_SCHEDULE_DATA);
export const getScheduleDataSuccess = createAction(constant.GET_SCHEDULE_DATA_SUCCESS);
export const getScheduleDataFailed = createAction(constant.GET_SCHEDULE_DATA_FAILED);

// Get Groups
export const getTargetGroups = createAction(constant.GET_TARGET_GROUPS);
export const getTargetGroupsSuccess = createAction(constant.GET_TARGET_GROUPS_SUCCESS);
export const getTargetGroupsFailed = createAction(constant.GET_TARGET_GROUPS_FAILED);

// Get Assets
export const getAssets = createAction(constant.GET_ASSETS);
export const getAssetsSuccess = createAction(constant.GET_ASSETS_SUCCESS);
export const getAssetsFailed = createAction(constant.GET_ASSETS_FAILED);

// Get Playbooks
export const getPlaybooks = createAction(constant.GET_PLAYBOOKS);
export const getPlaybooksSuccess = createAction(constant.GET_PLAYBOOKS_SUCCESS);
export const getPlaybooksFailed = createAction(constant.GET_PLAYBOOKS_FAILED);

// Get Minions
export const getMinions = createAction(constant.GET_MINIONS);
export const getMinionsSuccess = createAction(constant.GET_MINIONS_SUCCESS);
export const getMinionsFailed = createAction(constant.GET_MINIONS_FAILED);

// Get Expected Variables
export const getExpectedVariables = createAction(constant.GET_EXPECTED_VARIABLES);
export const getExpectedVariablesSuccess = createAction(constant.GET_EXPECTED_VARIABLES_SUCCESS);
export const getExpectedVariablesFailed = createAction(constant.GET_EXPECTED_VARIABLES_FAILED);

// Get variables mini
export const getVariablesMini = createAction(constant.GET_VARIABLES_MINI);
export const getVariablesMiniSuccess = createAction(constant.GET_VARIABLES_MINI_SUCCESS);
export const getVariablesMiniFailed = createAction(constant.GET_VARIABLES_MINI_FAILED);

// MODAL ACTIONS
export const actionCreateTargetModal = createAction(constant.CREATE_TARGET_MODAL);
export const actionAddToGroupModal = createAction(constant.ADD_TO_GROUP_MODAL);
export const actionDeleteTargetModal = createAction(constant.DELETE_TARGET_MODAL);

// ACTIONS

// Create target
export const createTarget = createAction(constant.CREATE_TARGET);
export const createTargetSuccess = createAction(constant.CREATE_TARGET_SUCCESS);
export const createTargetFailed = createAction(constant.CREATE_TARGET_FAILED);

// Edit target
export const editTarget = createAction(constant.EDIT_TARGET);
export const editTargetSuccess = createAction(constant.EDIT_TARGET_SUCCESS);
export const editTargetFailed = createAction(constant.EDIT_TARGET_FAILED);

// Add to group
export const addToGroup = createAction(constant.ADD_TO_GROUP);
export const addToGroupSuccess = createAction(constant.ADD_TO_GROUP_SUCCESS);
export const addToGroupFailed = createAction(constant.ADD_TO_GROUP_FAILED);

// Add to new group
export const addToNewGroup = createAction(constant.ADD_TO_NEW_GROUP);
export const addToNewGroupSuccess = createAction(constant.ADD_TO_NEW_GROUP_SUCCESS);
export const addToNewGroupFailed = createAction(constant.ADD_TO_NEW_GROUP_FAILED);

// Delete targets
export const deleteTargets = createAction(constant.DELETE_TARGETS);
export const deleteTargetsSuccess = createAction(constant.DELETE_TARGETS_SUCCESS);
export const deleteTargetsFailed = createAction(constant.DELETE_TARGETS_FAILED);

// Save configuration
export const savePlaybookConfig = createAction(constant.SAVE_PLAYBOOK_CONFIG);
export const savePlaybookConfigSuccess = createAction(constant.SAVE_PLAYBOOK_CONFIG_SUCCESS);
export const savePlaybookConfigFailed = createAction(constant.SAVE_PLAYBOOK_CONFIG_FAILED);
