import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import * as actions from "./actions";
import * as service from "./service";
import { makeRequest } from "helper/request";
import { routes } from "router";
import { navigate } from "helper/history";
import { errorHandler } from "helper/errorHandler";
import { addNotification } from "../notifications/actions";

const getRolesParams = state => {
  return {
    page: state.settings.rolesParams.page,
    pageSize: state.settings.rolesParams.pageSize,
    ordering: state.settings.rolesParams.ordering
  };
};

const getTemplatesParams = state => {
  return {
    page: state.settings.templatesParams.page,
    pageSize: state.settings.templatesParams.pageSize,
    ordering: state.settings.templatesParams.ordering
  };
};

const getUserProfilesParams = state => {
  return {
    page: state.settings.userProfiles.page,
    pageSize: state.settings.userProfiles.pageSize,
    ordering: state.settings.userProfiles.ordering,
    search: state.settings.userProfiles.search
  };
};

const getLabelsParams = state => {
  return {
    page: state.settings.labelsParams.page,
    pageSize: state.settings.labelsParams.pageSize,
    ordering: state.settings.labelsParams.ordering,
    search: state.settings.labelsParams.search
  };
};

// Workers //

// Set Templates Params
function* setTemplatesParamsWorker() {
  const { page, pageSize, ordering } = yield select(getTemplatesParams);
  yield put(
    actions.getTemplates({
      page,
      pageSize,
      ordering
    })
  );
}

// Get Templates
function* getTemplatesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTemplates), payload);
    yield put(actions.getTemplatesSuccess(response));
  } catch (error) {
    yield put(actions.getTemplatesFailed(error));
  }
}

// Get Template
function* getTemplateWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTemplate), payload);
    yield put(actions.getTemplateSuccess(response));
  } catch (error) {
    yield put(actions.getTemplateFailed(error));
  }
}

// Add Template
function* addTemplateWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addTemplate), payload);
    yield put(actions.addTemplateSuccess(response));
    yield put(
      addNotification({
        msg: `Ticket template created successfully`
      })
    );
    navigate(`${routes.settingsTicketTemplates}`);
  } catch (error) {
    yield put(actions.addTemplateFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Edit Template
function* editTemplateWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editTemplate), payload);
    yield put(actions.editTemplateSuccess(response));
    yield put(
      addNotification({
        msg: `Ticket template updated successfully`
      })
    );
    navigate(`${routes.settingsTicketTemplates}`);
  } catch (error) {
    yield put(actions.editTemplateFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete Template
function* deleteTemplateWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.deleteTemplate), id)));
    yield put(actions.deleteTemplateSuccess(response));
    yield put(actions.setTemplatesParams());
    yield put(
      addNotification({
        msg: `Ticket ${ids?.length > 1 ? "templates" : "template"} deleted successfully`
      })
    );
  } catch (error) {
    yield put(actions.deleteTemplateFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Duplicate Template
function* duplicateTemplateWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.duplicateTemplate), id)));
    yield put(actions.duplicateTemplateSuccess(response));
    yield put(actions.setTemplatesParams());
    yield put(
      addNotification({
        msg: `Ticket ${ids?.length > 1 ? "templates" : "template"} duplicated successfully`
      })
    );
  } catch (error) {
    yield put(actions.duplicateTemplateFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get Templates Data-types
function* getTemplatesDataTypesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTemplatesDataTypes), payload);
    yield put(actions.getTemplatesDataTypesSuccess(response));
  } catch (error) {
    yield put(actions.getTemplatesDataTypesSuccess(error));
  }
}

// Set Roles Params
function* setRolesParamsWorker() {
  const { page, pageSize, ordering } = yield select(getRolesParams);
  yield put(
    actions.getRoles({
      page,
      pageSize,
      ordering
    })
  );
}

// Get Roles
function* getRolesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getRoles), payload);
    yield put(actions.getRolesSuccess(response));
  } catch (error) {
    yield put(actions.getRolesFailed(error));
  }
}

// Get Role
function* getRoleWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getRole), payload);
    yield put(actions.getRoleSuccess(response));
  } catch (error) {
    yield put(actions.getRoleFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Add Role
function* addRoleWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addRole), payload);
    yield put(actions.addRoleSuccess(response));
    yield put(addNotification({ msg: "Role created successfully" }));
    navigate(routes.settingsRbac);
  } catch (error) {
    yield put(actions.addRoleFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Edit Role
function* editRoleWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editRole), payload);
    yield put(actions.editRoleSuccess(response));
    yield put(addNotification({ msg: "Role updated successfully" }));
    navigate(routes.settingsRbac);
  } catch (error) {
    yield put(actions.editRoleFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete Role
function* deleteRoleWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.deleteRole), id)));
    yield put(actions.deleteRoleSuccess(response));
    yield put(actions.setRolesParams());
    yield put(
      addNotification({ msg: `${ids?.length > 1 ? "Roles" : "Role"} deleted successfully` })
    );
  } catch (error) {
    yield put(actions.deleteRoleFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Get S3Profile
function* getS3ProfilesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getS3Profile), payload);
    yield put(actions.getS3ProfileSuccess(response));
  } catch (error) {
    yield put(actions.getS3ProfileFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Add S3Profile
function* addS3ProfilesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addS3Profile), payload);
    yield put(actions.addS3ProfileSuccess(response));
    yield put(actions.getS3Profile());
    yield put(actions.addModalS3Profile(false));
  } catch (error) {
    yield put(actions.addS3ProfileFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete S3Profile
function* deleteS3ProfileWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteS3Profile), payload);
    yield put(actions.deleteS3ProfileSuccess(response));
    yield put(actions.getS3Profile());
  } catch (error) {
    yield put(actions.deleteS3ProfileFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Edit S3Profile
function* editS3ProfileWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editS3Profile), payload);
    yield put(actions.editS3ProfileSuccess(response));
    yield put(actions.getS3Profile());
    navigate(routes.settings);
  } catch (error) {
    yield put(actions.editS3ProfileFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Jira Server
function* getJiraServerWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getJiraServer), payload);
    yield put(actions.getJiraServerSuccess(response));
  } catch (error) {
    yield put(actions.getJiraServerFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetJiraServerWorker() {
  yield takeEvery(actions.getJiraServer, getJiraServerWorker);
}

function* getOneJiraServerWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getOneJiraServer), payload);
    yield put(actions.getOneJiraServerSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getOneJiraServerFailed(error));
  }
}

function* watchGetOneJiraServerWorker() {
  yield takeEvery(actions.getOneJiraServer, getOneJiraServerWorker);
}

function* addJiraServerWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addJiraServer), payload);
    yield put(actions.addJiraServerSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addJiraServerFailed(error));
  }
}
function* watchAddJiraServerWorker() {
  yield takeEvery(actions.addJiraServer, addJiraServerWorker);
}

function* editJiraServerWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editJiraServer), payload);
    yield put(actions.editJiraServerSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(actions.editJiraServerFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchEditJiraServerWorker() {
  yield takeEvery(actions.editJiraServer, editJiraServerWorker);
}

function* deleteJiraServerWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteJiraServer), payload);
    yield put(actions.deleteJiraServerSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    yield put(actions.getJiraServer());
  } catch (error) {
    yield put(actions.deleteJiraServerFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchDeleteJiraServerWorker() {
  yield takeEvery(actions.deleteJiraServer, deleteJiraServerWorker);
}

// // Jira Cloud
function* getJiraCloudWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getJiraCloud), payload);
    yield put(actions.getJiraCloudSuccess(response));
  } catch (error) {
    yield put(actions.getJiraCloudFailed(error));
  }
}
function* watchGetJiraCloudWorker() {
  yield takeEvery(actions.getJiraCloud, getJiraCloudWorker);
}

function* getOneJiraCloudWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getOneJiraCloud), payload);
    yield put(actions.getOneJiraCloudSuccess(response));
  } catch (error) {
    yield put(actions.getOneJiraCloudFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetOneJiraCloudWorker() {
  yield takeEvery(actions.getOneJiraCloud, getOneJiraCloudWorker);
}

function* addJiraCloudWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addJiraCloud), payload);
    yield put(actions.addJiraCloudSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(actions.addJiraCloudFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchAddJiraCloudWorker() {
  yield takeEvery(actions.addJiraCloud, addJiraCloudWorker);
}

function* editJiraCloudWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editJiraCloud), payload);
    yield put(actions.editJiraCloudSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(actions.editJiraCloudFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchEditJiraCloudWorker() {
  yield takeEvery(actions.editJiraCloud, editJiraCloudWorker);
}

function* deleteJiraCloudWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteJiraCloud), payload);
    yield put(actions.deleteJiraCloudSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    yield put(actions.getJiraCloud());
  } catch (error) {
    yield put(actions.deleteJiraCloudFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchDeleteJiraCloudWorker() {
  yield takeEvery(actions.deleteJiraCloud, deleteJiraCloudWorker);
}

// // Freshservice
function* getFreshserviceWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getFreshservice), payload);
    yield put(actions.getFreshserviceSuccess(response));
  } catch (error) {
    yield put(actions.getFreshserviceFailed(error));
  }
}
function* watchGetFreshserviceWorker() {
  yield takeEvery(actions.getFreshservice, getFreshserviceWorker);
}

function* getOneFreshserviceWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getOneFreshservice), payload);
    yield put(actions.getOneFreshserviceSuccess(response));
  } catch (error) {
    yield put(actions.getOneFreshserviceFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

function* watchGetOneFreshserviceWorker() {
  yield takeEvery(actions.getOneFreshservice, getOneFreshserviceWorker);
}

function* addFreshserviceWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addFreshservice), payload);
    yield put(actions.addFreshserviceSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(actions.addFreshserviceFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchAddFreshserviceWorker() {
  yield takeEvery(actions.addFreshservice, addFreshserviceWorker);
}

function* editFreshserviceWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editFreshservice), payload);
    yield put(actions.editFreshserviceSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(actions.editFreshserviceFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchEditFreshserviceWorker() {
  yield takeEvery(actions.editFreshservice, editFreshserviceWorker);
}

function* deleteFreshserviceWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteFreshservice), payload);
    yield put(actions.deleteFreshserviceSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    yield put(actions.getFreshservice());
  } catch (error) {
    yield put(actions.deleteFreshserviceFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchDeleteFreshserviceWorker() {
  yield takeEvery(actions.deleteFreshservice, deleteFreshserviceWorker);
}

// ConnectWise
function* getConnectWiseWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getConnectWise), payload);
    yield put(actions.getConnectWiseSuccess(response));
  } catch (error) {
    yield put(actions.getConnectWiseFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetConnectWiseWorker() {
  yield takeEvery(actions.getConnectWise, getConnectWiseWorker);
}

function* getOneConnectWiseWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getOneConnectWise), payload);
    yield put(actions.getOneConnectWiseSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getOneConnectWiseFailed(error));
  }
}

function* watchGetOneConnectWiseWorker() {
  yield takeEvery(actions.getOneConnectWise, getOneConnectWiseWorker);
}

function* addConnectWiseWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addConnectWise), payload);
    yield put(actions.addConnectWiseSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addConnectWiseFailed(error));
  }
}
function* watchAddConnectWiseWorker() {
  yield takeEvery(actions.addConnectWise, addConnectWiseWorker);
}

function* editConnectWiseWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editConnectWise), payload);
    yield put(actions.editConnectWiseSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(actions.editConnectWiseFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchEditConnectWiseWorker() {
  yield takeEvery(actions.editConnectWise, editConnectWiseWorker);
}

function* deleteConnectWiseWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteConnectWise), payload);
    yield put(actions.deleteConnectWiseSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    yield put(actions.getConnectWise());
  } catch (error) {
    yield put(actions.deleteConnectWiseFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchDeleteConnectWiseWorker() {
  yield takeEvery(actions.deleteConnectWise, deleteConnectWiseWorker);
}

// // Email Backends

// tickets-config
function* getTicketsConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTicketsConfig), payload);
    yield put(actions.getTicketsConfigSuccess(response));
  } catch (error) {
    yield put(actions.getTicketsConfigFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetTicketsConfigWorker() {
  yield takeEvery(actions.getTicketsConfig, getTicketsConfigWorker);
}

function* getTicketConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTicketConfig), payload);
    yield put(actions.getTicketConfigSuccess(response));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.getTicketConfigFailed(error));
  }
}

function* watchGetTicketConfigWorker() {
  yield takeEvery(actions.getTicketConfig, getTicketConfigWorker);
}

function* addTicketConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addTicketConfig), payload);
    yield put(actions.addTicketConfigSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    yield put(actions.getTicketsConfig({ id: response?.ticket_profile_id }));
    yield put(actions.showTicketsConfigModal({ show: false, id: null, type: null }));
  } catch (error) {
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
    yield put(actions.addTicketConfigFailed(error));
  }
}
function* watchAddTicketConfigWorker() {
  yield takeEvery(actions.addTicketConfig, addTicketConfigWorker);
}

function* editTicketConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editTicketConfig), payload);
    yield put(actions.editTicketConfigSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    yield put(actions.getTicketsConfig({ id: response?.ticket_profile_id }));
    yield put(actions.showTicketsConfigModal({ show: false, id: null, type: null }));
  } catch (error) {
    yield put(actions.editTicketConfigFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchEditTicketConfigWorker() {
  yield takeEvery(actions.editTicketConfig, editTicketConfigWorker);
}

function* deleteTicketConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteTicketConfig), payload);
    yield put(actions.deleteTicketConfigSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    yield put(actions.getTicketsConfig({ id: payload?.ticket_profile_id }));
  } catch (error) {
    yield put(actions.deleteTicketConfigFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchDeleteTicketConfigWorker() {
  yield takeEvery(actions.deleteTicketConfig, deleteTicketConfigWorker);
}

function* getStatusForTicketConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getStatusForTicketConfig), payload);
    yield put(actions.getStatusForTicketConfigSuccess(response));
  } catch (error) {
    yield put(actions.getStatusForTicketConfigFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetStatusForTicketConfigWorker() {
  yield takeEvery(actions.getStatusForTicketConfig, getStatusForTicketConfigWorker);
}

function* getJiraAllWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getJiraAll), payload);
    yield put(actions.getJiraAllSuccess(response));
  } catch (error) {
    yield put(actions.getJiraAllFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetJiraAllWorker() {
  yield takeEvery(actions.getJiraAll, getJiraAllWorker);
}

function* getFreshserviceConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getFreshserviceConfig), payload);
    yield put(actions.getFreshserviceConfigSuccess(response));
  } catch (error) {
    yield put(actions.getFreshserviceConfigFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetFreshserviceConfigWorker() {
  yield takeEvery(actions.getFreshserviceConfig, getFreshserviceConfigWorker);
}

function* getConnectWiseConfigWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getConnectWiseConfig), payload);
    yield put(actions.getConnectWiseConfigSuccess(response));
  } catch (error) {
    yield put(actions.getConnectWiseConfigFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetConnectWiseConfigWorker() {
  yield takeEvery(actions.getConnectWiseConfig, getConnectWiseConfigWorker);
}

function* getTemplateListMiniWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getTemplateListMini), payload);
    yield put(actions.getTemplateListMiniSuccess(response));
  } catch (error) {
    yield put(actions.getTemplateListMiniFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetTemplateListMiniWorker() {
  yield takeEvery(actions.getTemplateListMini, getTemplateListMiniWorker);
}

// Get Email-profile
function* getEmailProfileWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getEmailProfile), payload);
    yield put(actions.getEmailProfileSuccess(response));
  } catch (error) {
    yield put(actions.getEmailProfileFailed(error));
  }
}
function* watchGetEmailProfileWorker() {
  yield takeEvery(actions.getEmailProfile, getEmailProfileWorker);
}

// Add Email-profile
function* addEmailProfileWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addEmailProfile), payload);
    yield put(actions.addEmailProfileSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(actions.addEmailProfileFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchAddEmailProfileWorker() {
  yield takeEvery(actions.addEmailProfile, addEmailProfileWorker);
}

// Edit Email-profile
function* editEmailProfileWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editEmailProfile), payload);
    yield put(actions.editEmailProfileSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(actions.editEmailProfileFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchEditEmailProfileWorker() {
  yield takeEvery(actions.editEmailProfile, editEmailProfileWorker);
}

// Delete Email-profile
function* deleteEmailProfileWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteEmailProfile), payload);
    yield put(actions.deleteEmailProfileSuccess(response));
    yield put(addNotification({ msg: "Success" }));
    yield put(actions.getEmailProfile());
    navigate(routes.settingsIntegrations);
  } catch (error) {
    yield put(actions.deleteEmailProfileFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchDeleteEmailProfileWorker() {
  yield takeEvery(actions.deleteEmailProfile, deleteEmailProfileWorker);
}

// Get Users
function* getUsersWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getUsers), payload);
    yield put(actions.getUsersSuccess(response));
  } catch (error) {
    yield put(actions.getUsersFailed(error));
  }
}

// Get Permissions
function* getPermissionsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getPermissions), payload);
    yield put(actions.getPermissionsSuccess(response));
  } catch (error) {
    yield put(actions.getPermissionsFailed(error));
  }
}

// User Profiles
function* getUserProfilesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getUserProfiles), payload);
    yield put(actions.getUserProfilesSuccess(response));
  } catch (error) {
    yield put(actions.getUserProfilesFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetUserProfilesWorker() {
  yield takeEvery(actions.getUserProfiles, getUserProfilesWorker);
}

function* setUserProfilesParamsWorker() {
  const { page, pageSize, ordering, search } = yield select(getUserProfilesParams);
  yield put(
    actions.getUserProfiles({
      page,
      pageSize,
      ordering,
      search
    })
  );
}
function* watchSetUserProfilesParamsWorker() {
  yield takeEvery(actions.setUserProfilesParams, setUserProfilesParamsWorker);
}

function* getOneUserProfileWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getOneUserProfile), payload);
    yield put(actions.getOneUserProfileSuccess(response));
  } catch (error) {
    yield put(actions.getOneUserProfileFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchGetOneUserProfileWorker() {
  yield takeEvery(actions.getOneUserProfile, getOneUserProfileWorker);
}

function* addUserProfilesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addUserProfiles), payload);
    yield put(actions.addUserProfilesSuccess(response));
    navigate(routes.settingsUserProfiles);
    yield put(addNotification({ msg: "Success" }));
  } catch (error) {
    yield put(actions.addUserProfilesFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchAddUserProfilesWorker() {
  yield takeEvery(actions.addUserProfiles, addUserProfilesWorker);
}

function* editUserProfilesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editUserProfiles), payload);
    yield put(actions.editUserProfilesSuccess(response));
    navigate(routes.settingsUserProfiles);
    yield put(addNotification({ msg: "Success" }));
  } catch (error) {
    yield put(actions.editUserProfilesFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchEditUserProfilesWorker() {
  yield takeEvery(actions.editUserProfiles, editUserProfilesWorker);
}

function* deleteUserProfilesWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.deleteUserProfiles), payload);
    yield put(actions.deleteUserProfilesSuccess(response));
    navigate(routes.settingsUserProfiles);
    yield put(addNotification({ msg: "Success" }));
  } catch (error) {
    yield put(actions.deleteUserProfilesFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
function* watchDeleteUserProfilesWorker() {
  yield takeEvery(actions.deleteUserProfiles, deleteUserProfilesWorker);
}

function* getUserProfilesGroupWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getUserProfilesGroup), payload);
    yield put(actions.getUserProfilesGroupSuccess(response));
  } catch (error) {
    yield put(actions.getUserProfilesGroupFailed(error));
  }
}
function* watchGetUserProfilesGroupWorker() {
  yield takeEvery(actions.getUserProfilesGroup, getUserProfilesGroupWorker);
}

// Labels
function* setLabelsParamsWorker() {
  const { page, pageSize, ordering, search } = yield select(getLabelsParams);
  yield put(
    actions.getSettingsLabels({
      page,
      pageSize,
      ordering,
      search
    })
  );
}

// Get Label
function* getSettingsLabelsWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getLabels), payload);
    yield put(actions.getSettingsLabelsSuccess(response));
  } catch (error) {
    yield put(actions.getSettingsLabelsFailed(error));
  }
}

// Get Label
function* getLabelWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.getLabel), payload);
    yield put(actions.getLabelSuccess(response));
  } catch (error) {
    yield put(actions.getLabelFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Add Label
function* addLabelWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.addLabel), payload);
    yield put(actions.addLabelSuccess(response));
    yield put(
      addNotification({
        msg: `Label created successfully`
      })
    );
    navigate(routes.settingsLabels);
  } catch (error) {
    yield put(actions.addLabelFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Edit Label
function* editLabelWorker({ payload }) {
  try {
    const response = yield call(makeRequest(service.editLabel), payload);
    yield put(actions.editLabelSuccess(response));
    yield put(
      addNotification({
        msg: `Label updated successfully`
      })
    );
    navigate(routes.settingsLabels);
  } catch (error) {
    yield put(actions.editLabelFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}

// Delete Settings Label
function* deleteSettingsLabelWorker({ payload }) {
  const { ids } = payload;
  try {
    const response = yield all(ids.map(id => call(makeRequest(service.deleteSettingsLabel), id)));
    yield put(actions.deleteSettingsLabelSuccess(response));
    yield put(actions.setLabelsParams());
    yield put(
      addNotification({
        msg: `${ids?.length > 1 ? "Labels" : "Label"} deleted successfully`
      })
    );
  } catch (error) {
    yield put(actions.deleteSettingsLabelFailed(error));
    yield put(addNotification({ msg: errorHandler(error), type: "error" }));
  }
}
// Workers End //

// Watchers//

// Set Templates Params
function* watchSetTemplatesParamsWorker() {
  yield takeEvery(actions.setTemplatesParams, setTemplatesParamsWorker);
}

// Get Templates
function* watchGetTemplatesWorker() {
  yield takeEvery(actions.getTemplates, getTemplatesWorker);
}

// Get Template
function* watchGetTemplateWorker() {
  yield takeEvery(actions.getTemplate, getTemplateWorker);
}

// Add Template
function* watchAddTemplateWorker() {
  yield takeEvery(actions.addTemplate, addTemplateWorker);
}

// Edit Template
function* watchEditTemplateWorker() {
  yield takeEvery(actions.editTemplate, editTemplateWorker);
}

// Delete Template
function* watchDeleteTemplateWorker() {
  yield takeEvery(actions.deleteTemplate, deleteTemplateWorker);
}

// Duplicate Template
function* watchDuplicateTemplateWorker() {
  yield takeEvery(actions.duplicateTemplate, duplicateTemplateWorker);
}

// Get Templates Data-types
function* watchGetTemplatesDataTypesWorker() {
  yield takeEvery(actions.getTemplatesDataTypes, getTemplatesDataTypesWorker);
}

// Set Roles Params
function* watchSetRolesParamsWorker() {
  yield takeEvery(actions.setRolesParams, setRolesParamsWorker);
}

// Get Roles
function* watchGetRolesWorker() {
  yield takeEvery(actions.getRoles, getRolesWorker);
}

// Get Role
function* watchGetRoleWorker() {
  yield takeEvery(actions.getRole, getRoleWorker);
}

// Add Role
function* watchAddRoleWorker() {
  yield takeEvery(actions.addRole, addRoleWorker);
}

// Edit Role
function* watchEditRoleWorker() {
  yield takeEvery(actions.editRole, editRoleWorker);
}

// Delete Role
function* watchDeleteRoleWorker() {
  yield takeEvery(actions.deleteRole, deleteRoleWorker);
}

// Get Users
function* watchGetUsersWorker() {
  yield takeEvery(actions.getUsers, getUsersWorker);
}

// Get Permissions
function* watchGetPermissionsWorker() {
  yield takeEvery(actions.getPermissions, getPermissionsWorker);
}

// Get S3Profile
function* watchGetS3ProfileWorker() {
  yield takeEvery(actions.getS3Profile, getS3ProfilesWorker);
}

// Add S3Profile
function* watchAddS3ProfileWorker() {
  yield takeEvery(actions.addS3Profile, addS3ProfilesWorker);
}

// Delete S3Profile
function* watchDeleteS3ProfileWorker() {
  yield takeEvery(actions.deleteS3Profile, deleteS3ProfileWorker);
}

// Edit S3Profile
function* watchEditS3ProfileWorker() {
  yield takeEvery(actions.editS3Profile, editS3ProfileWorker);
}

// Set Labels Params
function* watchSetLabelsParamsWorker() {
  yield takeEvery(actions.setLabelsParams, setLabelsParamsWorker);
}

// Get Label
function* watchGetSettingsLabelsWorker() {
  yield takeEvery(actions.getSettingsLabels, getSettingsLabelsWorker);
}

// Get Label
function* watchGetLabelWorker() {
  yield takeEvery(actions.getLabel, getLabelWorker);
}

// Add Label
function* watchAddLabelWorker() {
  yield takeEvery(actions.addLabel, addLabelWorker);
}

// Edit Label
function* watchEditLabelWorker() {
  yield takeEvery(actions.editLabel, editLabelWorker);
}

// Delete Label
function* watchDeleteSettingsLabelWorker() {
  yield takeEvery(actions.deleteSettingsLabel, deleteSettingsLabelWorker);
}

// Watchers End//

export default function* rootSaga() {
  yield all([
    fork(watchSetTemplatesParamsWorker),
    fork(watchGetTemplatesWorker),
    fork(watchGetTemplateWorker),
    fork(watchAddTemplateWorker),
    fork(watchEditTemplateWorker),
    fork(watchDeleteTemplateWorker),
    fork(watchDuplicateTemplateWorker),
    fork(watchGetTemplatesDataTypesWorker),

    fork(watchSetRolesParamsWorker),
    fork(watchGetRolesWorker),
    fork(watchGetRoleWorker),
    fork(watchAddRoleWorker),
    fork(watchEditRoleWorker),
    fork(watchDeleteRoleWorker),
    fork(watchGetUsersWorker),
    fork(watchGetPermissionsWorker),

    fork(watchGetS3ProfileWorker),
    fork(watchAddS3ProfileWorker),
    fork(watchDeleteS3ProfileWorker),
    fork(watchEditS3ProfileWorker),

    fork(watchGetEmailProfileWorker),
    fork(watchAddEmailProfileWorker),
    fork(watchEditEmailProfileWorker),
    fork(watchDeleteEmailProfileWorker),

    fork(watchGetJiraServerWorker),
    fork(watchGetOneJiraServerWorker),
    fork(watchAddJiraServerWorker),
    fork(watchEditJiraServerWorker),
    fork(watchDeleteJiraServerWorker),

    fork(watchGetJiraCloudWorker),
    fork(watchGetOneJiraCloudWorker),
    fork(watchAddJiraCloudWorker),
    fork(watchEditJiraCloudWorker),
    fork(watchDeleteJiraCloudWorker),

    fork(watchGetFreshserviceWorker),
    fork(watchGetOneFreshserviceWorker),
    fork(watchAddFreshserviceWorker),
    fork(watchEditFreshserviceWorker),
    fork(watchDeleteFreshserviceWorker),

    fork(watchGetConnectWiseWorker),
    fork(watchGetOneConnectWiseWorker),
    fork(watchAddConnectWiseWorker),
    fork(watchEditConnectWiseWorker),
    fork(watchDeleteConnectWiseWorker),

    fork(watchGetUserProfilesWorker),
    fork(watchSetUserProfilesParamsWorker),
    fork(watchGetOneUserProfileWorker),
    fork(watchAddUserProfilesWorker),
    fork(watchEditUserProfilesWorker),
    fork(watchDeleteUserProfilesWorker),
    fork(watchGetUserProfilesGroupWorker),

    fork(watchGetTicketsConfigWorker),
    fork(watchGetTicketConfigWorker),
    fork(watchAddTicketConfigWorker),
    fork(watchEditTicketConfigWorker),
    fork(watchDeleteTicketConfigWorker),
    fork(watchGetStatusForTicketConfigWorker),

    fork(watchGetJiraAllWorker),
    fork(watchGetFreshserviceConfigWorker),
    fork(watchGetConnectWiseConfigWorker),
    fork(watchGetTemplateListMiniWorker),

    fork(watchSetLabelsParamsWorker),
    fork(watchGetSettingsLabelsWorker),
    fork(watchGetLabelWorker),
    fork(watchAddLabelWorker),
    fork(watchEditLabelWorker),
    fork(watchDeleteSettingsLabelWorker)
  ]);
}
