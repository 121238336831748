import * as constant from "./constants";
import { createAction } from "@reduxjs/toolkit";

export const setAssetsParams = createAction(constant.SET_ASSETS_PARAMS);

export const actionGroupModal = createAction(constant.GROUP_MODAL);

// ADD GROUP
export const addGroup = createAction(constant.ADD_TO_GROUP);
export const addGroupSuccess = createAction(constant.ADD_TO_GROUP_SUCCESS);
export const addGroupFailed = createAction(constant.ADD_TO_GROUP_FAILED);

// Get Assets
export const getAssets = createAction(constant.GET_ASSETS);
export const getAssetsSuccess = createAction(constant.GET_ASSETS_SUCCESS);
export const getAssetsFailed = createAction(constant.GET_ASSETS_FAILED);

// Get Assets Mini
export const getAssetsMini = createAction(constant.GET_ASSETS_MINI);
export const getAssetsMiniSuccess = createAction(constant.GET_ASSETS_MINI_SUCCESS);
export const getAssetsMiniFailed = createAction(constant.GET_ASSETS_MINI_FAILED);

// Get Asset
export const getAsset = createAction(constant.GET_ASSET);
export const getAssetSuccess = createAction(constant.GET_ASSET_SUCCESS);
export const getAssetFailed = createAction(constant.GET_ASSET_FAILED);

// Set ASSET DataList types
export const getAssetDataListTypes = createAction(constant.GET_ASSET_DATALIST_TYPES);
export const getAssetDataListTypesSuccess = createAction(constant.GET_ASSET_DATALIST_TYPES_SUCCESS);
export const getAssetDataListTypesFailed = createAction(constant.GET_ASSET_DATALIST_TYPES_FAILED);

// Set ASSET DataList type
export const getAssetDataListType = createAction(constant.GET_ASSET_DATALIST_TYPE);
export const getAssetDataListTypeSuccess = createAction(constant.GET_ASSET_DATALIST_TYPE_SUCCESS);
export const getAssetDataListTypeFailed = createAction(constant.GET_ASSET_DATALIST_TYPE_FAILED);

// Set ASSET Tag
export const setAssetTag = createAction(constant.SET_ASSET_TAG);
export const setAssetTagSuccess = createAction(constant.SET_ASSET_TAG_SUCCESS);
export const setAssetTagFailed = createAction(constant.SET_ASSET_TAG_FAILED);

// Set ASSET Baseline
export const setAssetBaseline = createAction(constant.SET_ASSET_BASELINE);
export const setAssetBaselineSuccess = createAction(constant.SET_ASSET_BASELINE_SUCCESS);
export const setAssetBaselineFailed = createAction(constant.SET_ASSET_BASELINE_FAILED);

// Get Filters
export const getAssetFilterChoices = createAction(constant.GET_ASSET_FILTER_CHOICES);
export const getAssetFilterChoicesSuccess = createAction(constant.GET_ASSET_FILTER_CHOICES_SUCCESS);
export const getAssetFilterChoicesFailed = createAction(constant.GET_ASSET_FILTER_CHOICES_FAILED);

// Get visual dashboard data
export const getChartsData = createAction(constant.GET_CHARTS_DATA);
export const getChartsDataSuccess = createAction(constant.GET_CHARTS_DATA_SUCCESS);
export const getChartsDataFailed = createAction(constant.GET_CHARTS_DATA_FAILED);
