/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post, patch, remove } from "api/base";
import { formatDate } from "helper/DateLib";

const generatorFilterUrl = obj => {
  if (!obj) {
    return "";
  }
  let url = "";
  Object.entries(obj).forEach(([key, val]) => {
    if (key === "last_seen" || key === "date_discovered") {
      if (key === "last_seen") {
        const last_seen_after = formatDate(val[0]) || "";
        const last_seen_before = formatDate(val[1]) || "";
        url += `&last_seen_before=${last_seen_before}&last_seen_after=${last_seen_after}`;
      }
      if (key === "date_discovered") {
        const date_discovered_after = formatDate(val[0]) || "";
        const date_discovered_before = formatDate(val[1]) || "";
        url += `&date_discovered_before=${date_discovered_before}&date_discovered_after=${date_discovered_after}`;
      }
    } else {
      url += `&${key}=${val?.value}`;
    }
  });
  return url;
};

export const getBaselines = fields => {
  const { page, pageSize, ordering, search, filter, label } = fields;
  return get(
    `${API}/baselines?page=${page + 1 || 1}&ordering=${ordering}&search=${search ?? ""}&${
      label ? `label_id=${label?.value}` : ""
    }&page_size=${pageSize}&result=${filter?.key || ""}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getBaseline = ({ id }) => {
  return get(`${API}/baselines/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getBaselineAssets = fields => {
  const { id, page, pageSize, ordering, search } = fields;

  return get(
    `${API}/baseline-analysis?baseline=${id}&page=${page + 1 || 1}&ordering=${ordering}
    &search=${search ?? ""}&page_size=${pageSize}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getBaselineAnalysisMini = fields => {
  const { id, page, pageSize, ordering, search } = fields;

  return get(
    `${API}/baseline-analysis-mini?baseline=${id}&page=${page + 1 || 1}&ordering=${ordering}
    &search=${search ?? ""}&page_size=${pageSize}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getBaselineAssetsDetails = ({ id }) => {
  return get(`${API}/checks/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};

export const editBaseline = fields => {
  const { id, ...rest } = fields;
  return patch(`${API}/baselines/${id}`, JSON.stringify(rest), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const createBaseline = fields => {
  return post(`${API}/baselines`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteBaseline = id => {
  return remove(
    `${API}/baselines/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getAuditChoices = fields => {
  const { page, pageSize, ordering, search, source, label, audit_file } = fields;
  let url = "";

  if (source) {
    url += `&source=${source?.value}`;
  }
  if (label) {
    url += `&label=${label?.value}`;
  }
  if (audit_file) {
    url += `&audit_file=${audit_file?.value}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  if (ordering) {
    url += `&ordering=${ordering}`;
  }

  return get(
    `${API}/controls/audit-choices?page=${page + 1 || 1}&page_size=${pageSize ?? ""}${url}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getAuditChoicesMini = fields => {
  const { page, pageSize, ordering, search, source, label, audit_file } = fields;
  let url = "";

  if (source) {
    url += `&source=${source?.value}`;
  }
  if (label) {
    url += `&label=${label?.value}`;
  }
  if (audit_file) {
    url += `&audit_file=${audit_file?.value}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  if (ordering) {
    url += `&ordering=${ordering}`;
  }

  return get(
    `${API}/controls/audit-choices-mini?page=${page + 1 || 1}&page_size=${pageSize ?? ""}${url}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getBaselineSources = () => {
  return get(`${API}/sources/`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getBaselineTagById = ({ id, baselineId }) => {
  return get(`${API}/controls/${id}?baseline=${baselineId}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getBaselineConfigTable = fields => {
  const {
    id,
    page,
    pageSize,
    ordering,
    search,
    filters,
    groupBy,
    baselineConfigTemporaryDataFilter
  } = fields;

  let url = "";
  if (filters?.result) {
    url += `&result=${filters?.result?.value}`;
  }
  if (filters?.expected) {
    url += `&expected_result=${filters?.expected?.value}`;
  }
  if (filters?.ticket) {
    url += `&has_tickets=${filters?.ticket?.value}`;
  }
  if (filters?.annotation) {
    url += `&annotation=${filters?.annotation?.value}`;
  }

  if (groupBy?.value) {
    url += `&group_by=${groupBy?.value}`;
  }

  const filterUrl = generatorFilterUrl(baselineConfigTemporaryDataFilter);

  return get(
    `${API}/controls?baseline=${id}&page=${page + 1 || 1}&${
      ordering ? `ordering=${ordering}` : ""
    }&search=${search ?? ""}&page_size=${pageSize}${url}${filterUrl}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getSubBaselineConfigTable = fields => {
  const {
    id,
    filters,
    groupBy,
    baselineConfigTemporaryData,
    groupByValue,
    search,
    pageSize,
    page
  } = fields;
  let url = "";
  if (filters?.result) {
    url += `&result=${filters?.result?.value}`;
  }
  if (filters?.expected) {
    url += `&expected_result=${filters?.expected?.value}`;
  }
  if (filters?.ticket) {
    url += `&has_tickets=${filters?.ticket?.value}`;
  }
  if (filters?.annotation) {
    url += `&annotation=${filters?.annotation?.value}`;
  }
  if (groupBy?.value) {
    url += `&group_by=${groupBy?.value}`;
  }
  if (groupByValue) {
    url += `&${groupBy?.value}=${groupByValue}`;
  }

  if (search) {
    url += `&search=${search}`;
  }

  const filterUrl = generatorFilterUrl(baselineConfigTemporaryData?.filters);
  return get(
    `${API}/controls/collapsed-data?baseline=${id}&${url}${filterUrl}&page_size=${pageSize}&page=${page}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getBaselineConfigFilters = () => {
  return get(`${API}/baselines/choices`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const exportBaselineConfigurationTable = fields => {
  const { id, ids } = fields;
  if (!ids.length) {
    return get(`${API}/controls/export?baseline=${id}`, {
      ...authorization(),
      ...authorizationTenant()
    }).then(res => res.blob());
  }

  return post(
    `${API}/controls/export?baseline=${id}`,
    JSON.stringify({
      ids
    }),
    {
      ...contentTypeJson(),
      ...authorization(),
      ...authorizationTenant()
    }
  ).then(res => res.blob());
};

export const addControlBaselineConfigurationTable = fields => {
  const { id, ids, ...rest } = fields;
  if (!ids.length) {
    return get(`${API}/controls/add-control?baseline=${id}`, {
      ...authorization(),
      ...authorizationTenant()
    });
  }
  return post(`${API}/controls/add-control?baseline=${id}`, JSON.stringify({ ids, ...rest }), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteControlBaselineConfigurationTable = fields => {
  const { id, ids, ...rest } = fields;
  if (!ids.length) {
    return get(`${API}/controls/delete-control?baseline=${id}`, {
      ...authorization(),
      ...authorizationTenant()
    });
  }
  return post(`${API}/controls/delete-control?baseline=${id}`, JSON.stringify({ ids, ...rest }), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getCompareBaselines = fields => {
  const ids = fields?.ids;
  let url = "";
  ids?.forEach(item => {
    url += `&id=${item}`;
  });

  return get(`${API}/baselines/data-compare-baselines?${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const postBaselineTags = ({ data, fields }) => {
  const { filters, search } = fields;
  let url = "";

  if (filters?.result) {
    url += `&result=${filters?.result?.value}`;
  }
  if (filters?.expected) {
    url += `&expected_result=${filters?.expected?.value}`;
  }
  if (filters?.ticket) {
    url += `&has_tickets=${filters?.ticket?.value}`;
  }
  if (filters?.annotation) {
    url += `&annotation=${filters?.annotation?.value}`;
  }

  return post(
    `${API}/controls/batch-baseline-tag?baseline=${data.baseline_id}&search=${search ?? ""}${url}`,
    JSON.stringify(data),
    {
      ...authorization(),
      ...contentTypeJson(),
      ...authorizationTenant()
    }
  );
};

export const getBaselineControl = ({ baselineId, controlId }) => {
  return get(`${API}/baseline-tags?baseline=${baselineId}&control=${controlId}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const duplicateBaseline = ({ id }) => {
  return get(`${API}/baselines/${id}/duplicate`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getBaselineAssetsMini = fields => {
  const { label, search, baseline_id } = fields;
  let url = "";

  if (search || label) {
    url += `&search=${search}&label=${label}`;
  }

  return get(`${API}/assets-mini?baseline_id=${baseline_id}${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getBaselineGroups = fields => {
  const { search } = fields;

  return get(`${API}/groups?search=${search ?? ""}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getBaselineGroupsMini = fields => {
  const { search } = fields;

  return get(`${API}/groups-mini?search=${search ?? ""}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addBaselineAsset = ({ id, data }) => {
  return post(`${API}/baselines/${id}/add-asset`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteBaselineAsset = ({ id, data }) => {
  return post(`${API}/baselines/${id}/delete-asset`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const addBaselineGroup = ({ id, data }) => {
  return post(`${API}/baselines/${id}/add-group`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteBaselineGroup = ({ id, data }) => {
  return post(`${API}/baselines/${id}/delete-group`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const getLabelsList = () => {
  return get(`${API}/controls/label-choices`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getDynamicSelect = async fields => {
  const field = fields?.field;
  const search = fields?.search ?? "";
  const page = fields?.page || "";
  const id = fields?.id;

  let url = "";

  if (search) {
    url += `&field-search=${search}`;
  } else {
    url += `&page=${page}`;
  }

  return get(`${API}/controls/filter-options?baseline=${id}&field=${field}${url}`, {
    ...authorization(),
    ...authorizationTenant()
  }).then(res => res.json());
};
