import { Button, Input } from "components/simple";
import React, { useCallback, useState } from "react";
import StepsToGet from "../StepsToGet";

import styles from "./styles.module.scss";

const steps = {
  roleARN: {
    title: "Role ARN",
    steps: [
      "Log into your AWS account and navigate to the IAM console.",
      "Create a new IAM role.",
      'When prompted for a "trusted entity type" select: "AWS account".',
      'Choose "Another AWS account" and Enter "199488642388" for the Account ID.',
      'Check the box to "Require external ID" and enter the external ID displayed at the bottom.',
      'Ensure that "Require MFA" is not selected.',
      'Select the "SecurityAudit" managed policy.',
      "Enter a memorable role name and create the role.",
      "Then click on the role name and copy the role ARN to paste it here"
    ]
  }
};

const ManualSetup = ({ onBackHandler, onCancelHandler }) => {
  const [getSteps, setGetSteps] = useState("");
  const [state, setState] = useState({
    externalId: "",
    roleARN: ""
  });

  const onSave = e => {
    e.preventDefault();
  };

  const closeSteps = useCallback(() => {
    setGetSteps("");
  }, [setGetSteps]);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={onSave}>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>External ID</span>
          </div>
          <Input
            type="text"
            placeholder="Enter the External ID*"
            value={state.externalId}
            onChange={val => handleChange(val.target.value, "externalId")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Role ARN*</span>
            <span className={styles.showSteps} onClick={() => setGetSteps("roleARN")}>
              Show steps
            </span>
          </div>
          <Input
            type="text"
            placeholder="Enter the Role ARN"
            value={state.roleARN}
            onChange={val => handleChange(val.target.value, "roleARN")}
            required
          />
        </div>
        <div className={styles.btnContainer}>
          <Button variant="outline" onClick={onBackHandler}>
            Back
          </Button>
          <Button variant="outline" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button type="submit">Connect</Button>
        </div>
      </form>
      {getSteps && (
        <div className={styles.stepsContainer}>
          <StepsToGet steps={steps[getSteps]} onClose={closeSteps} />
        </div>
      )}
    </div>
  );
};

export default ManualSetup;
