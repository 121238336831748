import React, { useMemo } from "react";

import { Modal } from "components/simple";
import { Table } from "components/complex";
import renderCellExpand from "helper/renderCellExpand";

import styles from "./styles.module.scss";

const renderCol = obj => {
  const res = Object.entries(obj).map(([key]) => {
    return {
      headerName: key,
      field: key,
      resizable: true,
      minWidth: 10,
      flex: 1,
      renderCell: renderCellExpand,
      headerClassName: "super-app-theme--header",
      ...{
        ...(key === "id"
          ? {
              hide: true
            }
          : {})
      }
    };
  });
  return res;
};
const DataListModal = ({ open, close, data, isLoading }) => {
  const customStyles = {
    height: 500,
    width: 1,
    paddingTop: 0,
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none !important",
      borderTop: "none !important",
      "& .MuiDataGrid-columnHeaderTitle": {
        textTransform: "capitalize"
      }
    },
    "& .super-app-theme--header": {
      backgroundColor: "#fff"
    },
    "& .super-app-theme--row": {
      backgroundColor: "#fff",
      borderTop: "1px solid #E7E6F8",
      boxShadow: "0px 5px 30px rgba(38, 38, 38, 0.05)",
      // borderRadius: "10px",
      marginBottom: "5px",
      "&:last-child": {
        marginBottom: 0
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none !important"
      }
    }
  };

  const tableConfig = {
    checkboxVisible: false,
    paginationVisible: false,
    hideFooter: false,
    sortingMode: false
  };

  const dd = useMemo(() => {
    return data.map((item, index) => {
      return {
        ...item,
        id: index
      };
    });
  }, [data]);

  const columns = useMemo(() => {
    if (dd?.[0]) {
      return renderCol(dd[0]);
    }
    return [];
  }, [dd]);

  return (
    <Modal isOpen={open} toggle={close} width={900}>
      <div className={styles.container}>
        <Table
          data={isLoading ? [] : dd}
          columns={columns}
          loading={isLoading}
          customStyles={customStyles}
          {...tableConfig}
        />
      </div>
    </Modal>
  );
};

export default DataListModal;
