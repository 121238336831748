import React from "react";

export const cloudAccountsColumns = [
  {
    headerName: "Cloud",
    field: "cloud",
    resizable: true,
    minWidth: 255,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: true
  },
  {
    headerName: "Account",
    field: "account",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Group",
    field: "group",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Connected",
    field: "connected",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Enabled",
    field: "enabled",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Last scanned",
    field: "lastScanned",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  },
  {
    headerName: "Scan",
    field: "scan",
    resizable: true,
    minWidth: 130,
    flex: 1,
    renderCell: params => <div>{params.value || ""}</div>,
    headerClassName: "super-app-theme--header",
    hide: false,
    sortable: false
  }
];
