/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post, patch, remove } from "api/base";

export const getGroups = fields => {
  const { page, pageSize, ordering, search } = fields;

  let url = "";
  if (ordering) {
    url += `&ordering=${ordering}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  return get(`${API}/groups?page=${page + 1 || 1}&page_size=${pageSize}${url}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getGroup = id => {
  return get(`${API}/groups/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const createGroup = data => {
  return post(`${API}/groups`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const updateGroup = fields => {
  const { id, data } = fields;
  return patch(`${API}/groups/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteGroup = id => {
  return remove(
    `${API}/groups/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getGroupsMini = fields => {
  const { page, pageSize, search } = fields;
  return get(`${API}/groups-mini?page=${page + 1 || 1}&page_size=${pageSize}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getAssetsMini = fields => {
  const { page, pageSize, search } = fields;

  return get(`${API}/assets-mini?page=${page + 1 || 1}&page_size=${pageSize}&search=${search}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getGroupAssets = fields => {
  const { page, pageSize, ordering, search, groupId } = fields;

  let url = "";
  if (ordering) {
    url += `&ordering=${ordering}`;
  }
  if (search) {
    url += `&search=${search}`;
  }
  return get(
    `${API}/assets?group_id=${groupId}&page=${page + 1 || 1}&page_size=${pageSize}${url}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};
