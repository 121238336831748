import { Button, Select } from "components/simple";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { labelsMiniSelector } from "store/entities/labels/selectors";
import { getExpectedVariables, savePlaybookConfig } from "store/entities/targets/actions";
import {
  getMinionsSelector,
  getPlaybooksSelector,
  getVariableOptionsSelector,
  getVariablesSelector
} from "store/entities/targets/selectors";
import SoarcastSchedule from "./SoarcastSchedule";

import styles from "./styles.module.scss";

const SoarcastParams = ({ tagParams, tagId, setTagParams }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const playbooksOptions = useSelector(getPlaybooksSelector);
  const minionsOptions = useSelector(getMinionsSelector);
  const labelsOptions = useSelector(labelsMiniSelector);
  const parameters = useSelector(getVariablesSelector);
  const variableOptions = useSelector(getVariableOptionsSelector);

  const changeHandler = (value, name) => {
    setTagParams(prevParams => ({
      ...prevParams,
      [tagId]: {
        ...tagParams,
        [name]: value
      }
    }));
  };

  const variablesHandler = (value, name) => {
    setTagParams(prevParams => ({
      ...prevParams,
      [tagId]: {
        ...tagParams,
        parameters: {
          ...tagParams.parameters,
          [name]: value
        }
      }
    }));
  };

  const setSchedule = (value, name) => {
    setTagParams(prevParams => ({
      ...prevParams,
      [tagId]: {
        ...tagParams,
        schedule: {
          ...tagParams.schedule,
          [name]: value
        }
      }
    }));
  };

  const saveConfig = () => {
    dispatch(savePlaybookConfig({ tagParams, tagId, target: params.id }));
  };

  useEffect(() => {
    if (tagParams.playbook?.value) {
      dispatch(getExpectedVariables({ playbook_id: tagParams.playbook?.value }));
    }
  }, [tagParams.playbook?.value]);

  // useEffect(() => {
  //   setTagParams(prevParams => ({
  //     ...prevParams,
  //     [tagId]: {
  //       ...tagParams,
  //       parameters: {
  //         ...tagParams.parameters,
  //         [name]: value
  //       }
  //     }
  //   }));
  // }, [parameters])

  return (
    <div>
      <SoarcastSchedule schedule={tagParams.schedule} setSchedule={setSchedule} />
      <div className={styles.soarcastTitle}>SoarCast</div>
      <div className={styles.selectFieldsContainer}>
        <Select
          placeholder="Playbook"
          name="playbook"
          onChange={changeHandler}
          value={tagParams.playbook}
          options={playbooksOptions}
          containerClass={styles.select}
          isClearable
        />
        <Select
          placeholder="Minion"
          name="minion"
          onChange={changeHandler}
          value={tagParams.minion}
          options={minionsOptions}
          containerClass={styles.select}
          isClearable
        />
        <Select
          placeholder="Label"
          name="label"
          onChange={changeHandler}
          value={tagParams.label}
          options={labelsOptions}
          containerClass={styles.select}
          isClearable
        />
      </div>
      {parameters.length > 0 && (
        <>
          <div className={styles.sectionTitle}>Parameters</div>
          <div className={styles.parametersContainer}>
            {parameters.map(variable => (
              <Select
                key={variable.id}
                placeholder={variable.name}
                options={variableOptions}
                value={tagParams?.parameters?.[variable.name]}
                onChange={e => variablesHandler(e.target.value, variable.name)}
                className={styles.varibleInput}
              />
            ))}
          </div>
        </>
      )}
      <div className={styles.buttonsGroup}>
        <Button className={styles.buttonsGroupItem} disabled={null} onClick={saveConfig}>
          Save
        </Button>
        <Button className={styles.buttonsGroupItem} variant="outline" onClick={() => {}}>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default SoarcastParams;
