import DropDownArrow from "./DropDownArrow";
import Plus from "./Plus";
import Close from "./Close";

import AddTag from "./AddTag";
import AddToGroup from "./AddToGroup";
import AddToTarget from "./AddToTarget";
import Annotation from "./Annotation";
import CreateTicket from "./CreateTicket";
import Delete from "./Delete";
import Edit from "./Edit";
import Export from "./Export";
import IgnoreFinding from "./IgnoreFinding";
import Justification from "./Justification";
import Run from "./Run";
import Add from "./Add";
import Message from "./Message";
import Search from "./Search";
import Save from "./Save";
import Calendar from "./Calendar";
import Arrows from "./Arrows";
import File from "./File";
import PlusInCircle from "./PlusInCircle";
import MinusInCircle from "./MinusInCircle";
import Check from "./Check";
import NotCheck from "./NotCheck";
import Warning from "./Warning";
import ArrowBack from "./ArrowBack";
import QuestionMark from "./QuestionMark";
import User from "./User";
import Lock from "./Lock";
import Bell from "./Bell";
import ExpendArrow from "./ExpendArrow";
import NavArrow from "./NavArrow";
import RedirectArrow from "./RedirectArrow";
import Download from "./Download";

import dashboard from "./sidebar/dashboard";
import datastructure from "./sidebar/datastructure";
import vulnerabilities from "./sidebar/vulnerabilities";
import baselines from "./sidebar/baselines";
import targets from "./sidebar/targets";
import tickets from "./sidebar/tickets";
import reports from "./sidebar/reports";
import monitors from "./sidebar/monitors";
import notifications from "./sidebar/notifications";
import settings from "./sidebar/settings";
import logout from "./sidebar/logout";

const icons = {
  PlusInCircle,
  MinusInCircle,
  DropDownArrow,
  NavArrow,
  RedirectArrow,
  Plus,
  Close,
  AddTag,
  AddToGroup,
  AddToTarget,
  Annotation,
  CreateTicket,
  Delete,
  Edit,
  Export,
  IgnoreFinding,
  Justification,
  Run,
  Add,
  Message,
  Search,
  Save,
  Calendar,
  Arrows,
  File,
  Check,
  NotCheck,
  Warning,
  ArrowBack,
  QuestionMark,
  User,
  Lock,
  Bell,
  ExpendArrow,
  Download,
  Monitor: monitors,

  dashboard,
  datastructure,
  vulnerabilities,
  baselines,
  targets,
  tickets,
  reports,
  monitors,
  notifications,
  settings,
  logout
};

export default icons;
