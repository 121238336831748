/* eslint-disable */

// auth
export const loginScreen = "/login";
export const forgotPassword = "/forgot-password";
export const verification = "/verification";
export const resetPasswordScreen = "/reset-password";

// sidebar
export const home = "/";

export const dataStructure = "/data-structure";
export const dataStructureHistory = "/data-structure/import-history";
export const dataStructureTags = "/data-structure/tags";
export const dataStructureTagsDetails = "/data-structure/tags/details";
export const dataStructureGroups = "/data-structure/groups";
export const dataStructureGroupsDetails = "/data-structure/groups/details";
export const dataStructureDatalist = "/data-structure/datalist";
export const dataStructureDatalistCreateTicket = "/data-structure/datalist/create-ticket";

export const dataStructureAssets = "/data-structure/assets";
export const dataStructureAssetsDetails = "/data-structure/assets/details";

export const dataStructureLabels = "/data-structure/labels";

export const dataStructureBaselines = "/data-structure/baselines";

export const vulnerabilities = "/vulnerabilities";
export const vulnerabilitiesDetails = "/vulnerabilities/details";
export const vulnerabilitiesCreateTicket = "/vulnerabilities/create-ticket";

export const baselines = "/baselines";
export const failedBaseline = "/baselines/failed-assets";
export const createBaseline = "/baselines/create-baseline";
export const editBaseline = "/baselines/edit-baseline";
export const baselineConfiguration = "/baselines/baseline-configuration";
export const baselineConfigurationCreateTicket = "/baselines/baseline-configuration/create-ticket";
export const compareBaselines = "/baselines/compare";

export const targets = "/targets";
export const targetsDetails = "/targets/details";

export const cloudAccounts = "/cloud-accounts";
export const addCloudAccount = "/cloud-accounts/add-account";

export const tickets = "/tickets";
export const ticketsDetails = "/tickets/details";

export const reports = "/reports";
export const reportsDetails = "/reports/details";

export const notifications = "/notifications";
export const notificationsDetails = "/notifications/details";

// SETTINGS
export const settings = "/settings";
export const settingsDataSourceEdit = "/settings/data-source/edit";

export const settingsIntegrations = "/settings/integrations";
export const settingsIntegrationsJiraServer = "/settings/integrations/jira-server";
export const settingsIntegrationsJiraCloud = "/settings/integrations/jira-cloud";
export const settingsIntegrationsFreshservice = "/settings/integrations/freshservice";
export const settingsIntegrationsEmailsDetails = "/settings/integrations/emails-details";
export const settingsIntegrationsConnectWise = "/settings/integrations/connectwise";

export const settingsTicketTemplates = "/settings/ticket-templates";
export const settingsTicketTemplatesDetails = "/settings/ticket-templates/details";

export const settingsRbac = "/settings/rbac";
export const settingsRbacRoleDetails = "/settings/rbac/details";

export const settingsLabels = "/settings/labels";
export const settingsLabelsDetails = "/settings/labels/details";
export const settingsUserProfiles = "/settings/user-profiles";
export const settingsUserProfilesDetails = "/settings/user-profiles/details";
// SETTINGS END

export const profile = "/profile";

// Monitor

export const monitors = "/monitors";
export const monitorsConfiguration = "/monitors/monitors-configuration";

const routes = {
  loginScreen,
  forgotPassword,
  verification,
  resetPasswordScreen,
  home,
  dataStructure,
  dataStructureHistory,
  dataStructureTags,
  dataStructureTagsDetails,
  dataStructureGroups,
  dataStructureGroupsDetails,
  dataStructureDatalist,
  dataStructureDatalistCreateTicket,
  dataStructureAssets,
  dataStructureAssetsDetails,
  dataStructureLabels,
  dataStructureBaselines,
  vulnerabilities,
  vulnerabilitiesDetails,
  vulnerabilitiesCreateTicket,
  baselines,
  targets,
  targetsDetails,
  cloudAccounts,
  addCloudAccount,
  tickets,
  ticketsDetails,
  reports,
  reportsDetails,
  notifications,
  notificationsDetails,
  settings,
  settingsDataSourceEdit,
  settingsIntegrations,
  settingsIntegrationsJiraServer,
  settingsIntegrationsJiraCloud,
  settingsIntegrationsFreshservice,
  settingsIntegrationsEmailsDetails,
  settingsIntegrationsConnectWise,
  settingsTicketTemplates,
  settingsTicketTemplatesDetails,
  settingsRbac,
  settingsRbacRoleDetails,
  settingsLabels,
  settingsLabelsDetails,
  settingsUserProfiles,
  settingsUserProfilesDetails,
  profile,
  createBaseline,
  editBaseline,
  baselineConfiguration,
  failedBaseline,
  baselineConfigurationCreateTicket,
  compareBaselines,
  monitors,
  monitorsConfiguration
};
export default routes;
