import { all } from "redux-saga/effects";

import users from "../entities/users/saga";
import vulnerabilities from "../entities/vulnerabilities/saga";
import targets from "../entities/targets/saga";
import labels from "../entities/labels/saga";
import assets from "../entities/assets/saga";
import tickets from "../entities/tickets/saga";
import baseline from "../entities/baseline/saga";
import reports from "../entities/reports/saga";
import settings from "../entities/settings/saga";
import datalist from "../entities/datalist/saga";
import importHistory from "../entities/importHistory/saga";
import tags from "../entities/tags/saga";
import groups from "../entities/groups/saga";
import notifications from "../entities/notifications/saga";
import dashboard from "../entities/dashboard/saga";
import monitors from "../entities/monitors/saga";
import auditFiles from "../entities/auditfiles/saga";

export default function* rootSaga() {
  yield all([
    users(),
    vulnerabilities(),
    targets(),
    labels(),
    assets(),
    tickets(),
    baseline(),
    settings(),
    reports(),
    datalist(),
    importHistory(),
    tags(),
    groups(),
    notifications(),
    dashboard(),
    monitors(),
    auditFiles()
  ]);
}
