/* Core */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
/* Actions */
import {
  getBaselineSources,
  setAuditChoicesParams,
  getAuditChoices
} from "store/entities/baseline/actions";
import {
  getAuditFileAssets,
  getAuditFileBaselines,
  getAuditFileHistory,
  getAuditFileLabels,
  getAuditFiles
} from "store/entities/auditfiles/actions";
/* Selectors */
import { getAuditChoicesSelect, getSourcesListSelect } from "store/entities/baseline/selectors";
import { getLabelListSelector } from "store/entities/labels/selectors";
import { getAuditFilesSelector } from "store/entities/auditfiles/selectors";
/* Components */
import { Table } from "components/complex";
import { ActionButton, ContentContainer, Search, Select, Title } from "components/simple";
import { CreateBaselineModal, DetailModal } from ".";
/* Helpers */
import { onChangeUrlParams, useQuery } from "helper/history";
import { getLabelList } from "store/entities/labels/actions";
/* Styles */
import { styledTable } from "./styled";
import styles from "./styles.module.scss";
import { createLoadingSelector } from "store/entities/loading/selector";

import { useGridApiRef } from "@mui/x-data-grid-pro";
import { addNotification } from "store/entities/notifications/actions";

const Baselines = () => {
  const dispatch = useDispatch();
  const query = useQuery();

  const apiRef = useGridApiRef();
  const queryPage = query.get("page");

  const selectTenant = useSelector(state => state.users.selectTenant);
  const labelsList = useSelector(getLabelListSelector);
  const sourcesList = useSelector(getSourcesListSelect);
  const auditFilesList = useSelector(getAuditFilesSelector);

  const { data, columns, total } = useSelector(getAuditChoicesSelect);
  const { page, pageSize, source, label, audit_file } = useSelector(
    ({ baseline: { auditChoicesParams } }) => auditChoicesParams
  );

  const isLoading = useSelector(state => createLoadingSelector([getAuditChoices.type])(state));

  const isModalDataLoading = useSelector(
    state =>
      createLoadingSelector([getAuditFileLabels.type])(state) ||
      createLoadingSelector([getAuditFileAssets.type])(state) ||
      createLoadingSelector([getAuditFileBaselines.type])(state) ||
      createLoadingSelector([getAuditFileHistory.type])(state)
  );

  const [selectionModel, setSelectionModel] = useState([]);
  const prevSelectionModel = useRef(selectionModel);

  const selectedCells = data?.filter(item => selectionModel?.find(elem => elem === item?.id));

  const [createBaselineModal, setCreateBaselineModal] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState({ open: false, type: "", fileName: "" });

  const clearUrl = () => {
    onChangeUrlParams("page", 0);
    onChangeUrlParams("label", "");
    onChangeUrlParams("source", "");
    onChangeUrlParams("audit_file", "");
  };

  const getRequest = () => {
    onChangeUrlParams("page", 0);
    dispatch(
      setAuditChoicesParams({
        page: queryPage ? queryPage - 1 : 0,
        ordering: "",
        search: "",
        source: "",
        label: "",
        audit_file: ""
      })
    );
  };

  useEffect(() => {
    getRequest();
    dispatch(getAuditFiles());
    dispatch(getBaselineSources());
    dispatch(getLabelList());

    return () => clearUrl();
  }, [selectTenant]);

  useEffect(() => {
    if (prevSelectionModel.current?.length) {
      setSelectionModel(prevSelectionModel.current);
    }
  }, [page, data]);

  const setPageSize = v => {
    dispatch(setAuditChoicesParams({ pageSize: v }));
  };

  // Next page
  const onPageChange = newPage => {
    onChangeUrlParams("page", newPage + 1);
    prevSelectionModel.current = selectionModel;

    if (page !== -1) {
      dispatch(setAuditChoicesParams({ page: newPage }));
    }
  };

  // Search
  const handleSearch = text => {
    onChangeUrlParams("page", 0);
    dispatch(setAuditChoicesParams({ page: 0, search: text }));
  };

  const onCreateBaseline = () => {
    if (selectedCells.length > 0) setCreateBaselineModal(true);
    else dispatch(addNotification({ msg: " Choose audit file", type: "warning" }));
  };

  // Cell Click Listeners
  const onLabelCellClick = (fileName, type) => {
    dispatch(getAuditFileLabels(fileName));

    setShowDetailModal({ open: true, type });
  };

  const onAssetsCellClick = (fileName, type) => {
    dispatch(getAuditFileAssets({ fileName }));

    setShowDetailModal({ open: true, type, fileName });
  };

  const onBaselinesCellClick = (fileName, type) => {
    dispatch(getAuditFileBaselines({ fileName }));

    setShowDetailModal({ open: true, type, fileName });
  };

  const onHistoryCellClick = (fileName, type) => {
    dispatch(getAuditFileHistory(fileName));

    setShowDetailModal({ open: true, type, fileName });
  };

  const onCellClick = (v, e) => {
    const { row, field } = v;
    e.stopPropagation();
    if (row?.[field] !== 0) {
      const actionCols = {
        labels_count: onLabelCellClick,
        assets_count: onAssetsCellClick,
        baselines_count: onBaselinesCellClick,
        history_count: onHistoryCellClick
      };
      actionCols[field](row?.audit_file, field);
    }
  };

  // Sorting handle
  const handleSort = s => {
    if (s.length) {
      const { field, sort } = s[0];
      if (sort === "asc") {
        dispatch(setAuditChoicesParams({ ordering: field }));
      } else {
        dispatch(setAuditChoicesParams({ ordering: `-${field}` }));
      }
    } else {
      dispatch(setAuditChoicesParams({ ordering: "" }));
    }
  };

  // Filter Listener
  const handleFilter = (val, key) => {
    const res = {
      [key]: val
    };

    switch (key) {
      case "label":
        return (
          onChangeUrlParams("label", val?.label),
          dispatch(setAuditChoicesParams({ label: res?.label }))
        );
      case "source":
        return (
          onChangeUrlParams("source", val?.label),
          dispatch(setAuditChoicesParams({ source: res?.source }))
        );
      case "audit_file":
        return (
          onChangeUrlParams("audit_file", val?.label),
          dispatch(setAuditChoicesParams({ audit_file: res?.audit_file }))
        );
      default:
        clearUrl();
        dispatch(
          setAuditChoicesParams({
            source: "",
            label: "",
            audit_file: ""
          })
        );
    }
  };

  return (
    <>
      <ContentContainer className={styles.container}>
        <Title>Baselines</Title>
        <section className={styles.action}>
          <Search onSearch={handleSearch} />
          <div className={styles["action-group"]}>
            <div className={styles["action-group__filters"]}>
              <Select
                value={audit_file}
                onChange={v => handleFilter(v, "audit_file")}
                placeholder="Filter by Audit file"
                options={auditFilesList}
                containerClass={styles["action-group__filters-option"]}
                isClearable
              />
              <Select
                value={label}
                options={labelsList}
                onChange={v => handleFilter(v, "label")}
                placeholder="Filter by Label"
                containerClass={styles["action-group__filters-option"]}
                isClearable
              />
              <Select
                value={source}
                options={sourcesList}
                onChange={v => handleFilter(v, "source")}
                placeholder="Filter by Source"
                containerClass={styles["action-group__filters-option"]}
                isClearable
              />
            </div>
            <ActionButton
              type="addBaseline"
              onClick={onCreateBaseline}
              className={styles.actionButton}
            />
          </div>
        </section>
        <section>
          <Table
            apiRef={apiRef}
            data={data}
            columns={columns}
            rowCount={total}
            loading={isLoading}
            selectionModel={selectionModel}
            page={page || 0}
            pageSize={pageSize}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onSortModelChange={model => handleSort(model)}
            onPageChange={onPageChange}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            onSelectionModelChange={newSelectionModel => setSelectionModel(newSelectionModel)}
            onCellClick={(p, e) => onCellClick(p, e)}
            initialState={{ pinnedColumns: { left: ["__check__"] } }}
            paginationMode="server"
            customStyles={styledTable}
          />
        </section>
      </ContentContainer>
      <DetailModal
        type={showDetailModal.type}
        file={showDetailModal.fileName}
        loading={isModalDataLoading}
        isOpen={Boolean(showDetailModal.open)}
        onClose={() => setShowDetailModal(!showDetailModal.open)}
      />
      <CreateBaselineModal
        isOpen={Boolean(createBaselineModal)}
        onClose={() => setCreateBaselineModal(!createBaselineModal)}
        data={selectedCells[selectedCells.length - 1]}
        labels={labelsList}
        files={auditFilesList}
      />
    </>
  );
};

export default Baselines;
