import React from "react";
import { useHistory } from "react-router-dom";
import { routes } from "router/router";
import { Table } from "components/complex";
import { Button, ContentContainer, Search } from "components/simple";
import { cloudAccountsColumns } from "./tableColumns";

import styles from "./styles.module.scss";

const CloudAccounts = () => {
  const history = useHistory();

  const onAddAccountClick = () => {
    history.push(routes.addCloudAccount);
  };

  return (
    <ContentContainer className={styles.container}>
      <div className={styles.controls}>
        <Search />
        <Button onClick={onAddAccountClick}>Add Account</Button>
      </div>
      <Table data={[]} columns={cloudAccountsColumns} checkboxVisible={false} />
    </ContentContainer>
  );
};

export default CloudAccounts;
