export const SET_ASSETS_PARAMS = "SET_ASSETS_PARAMS";

export const GROUP_MODAL = "GROUP_MODAL_ASSETS";

// Add Group
export const ADD_TO_GROUP = "ADD_TO_GROUP_ASSETS_REQUEST";
export const ADD_TO_GROUP_SUCCESS = "ADD_TO_GROUP_ASSETS_SUCCESS";
export const ADD_TO_GROUP_FAILED = "ADD_TO_GROUP_ASSETS_FAILED";

// Get ASSETS
export const GET_ASSETS = "GET_GET_ASSETS_REQUEST";
export const GET_ASSETS_SUCCESS = "GET_GET_ASSETS_SUCCESS";
export const GET_ASSETS_FAILED = "GET_GET_ASSETS_FAILED";

// Get ASSETS
export const GET_ASSETS_MINI = "GET_ASSETS_MINI_REQUEST";
export const GET_ASSETS_MINI_SUCCESS = "GET_ASSETS_MINI_SUCCESS";
export const GET_ASSETS_MINI_FAILED = "GET_ASSETS_MINI_FAILED";

// Get ASSET
export const GET_ASSET = "GET_GET_ASSET_REQUEST";
export const GET_ASSET_SUCCESS = "GET_GET_ASSET_SUCCESS";
export const GET_ASSET_FAILED = "GET_GET_ASSET_FAILED";

// Get ASSET DataList types
export const GET_ASSET_DATALIST_TYPES = "GET_ASSET_DATALIST_TYPES_REQUEST";
export const GET_ASSET_DATALIST_TYPES_SUCCESS = "GET_ASSET_DATALIST_TYPES_SUCCESS";
export const GET_ASSET_DATALIST_TYPES_FAILED = "GET_ASSET_DATALIST_TYPES_FAILED";

// Get ASSET DataList type
export const GET_ASSET_DATALIST_TYPE = "GET_ASSET_DATALIST_TYPE_REQUEST";
export const GET_ASSET_DATALIST_TYPE_SUCCESS = "GET_ASSET_DATALIST_TYPE_SUCCESS";
export const GET_ASSET_DATALIST_TYPE_FAILED = "GET_ASSET_DATALIST_TYPE_FAILED";

// Set ASSET Tag
export const SET_ASSET_TAG = "SET_ASSET_TAG_REQUEST";
export const SET_ASSET_TAG_SUCCESS = "SET_ASSET_TAG_SUCCESS";
export const SET_ASSET_TAG_FAILED = "SET_ASSET_TAG_FAILED";

// Set ASSET Baseline
export const SET_ASSET_BASELINE = "SET_ASSET_BASELINE_REQUEST";
export const SET_ASSET_BASELINE_SUCCESS = "SET_ASSET_BASELINE_SUCCESS";
export const SET_ASSET_BASELINE_FAILED = "SET_ASSET_BASELINE_FAILED";

export const GET_ASSET_FILTER_CHOICES = "GET_ASSET_FILTER_CHOICES_REQUEST";
export const GET_ASSET_FILTER_CHOICES_SUCCESS = "GET_ASSET_FILTER_CHOICES_SUCCESS";
export const GET_ASSET_FILTER_CHOICES_FAILED = "GET_ASSET_FILTER_CHOICES_FAILED";

// Get visual dashboard data
export const GET_CHARTS_DATA = "GET_CHARTS_DATA";
export const GET_CHARTS_DATA_SUCCESS = "GET_CHARTS_DATA_SUCCESS";
export const GET_CHARTS_DATA_FAILED = "GET_CHARTS_DATA_FAILED";
