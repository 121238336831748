import React, { memo } from "react";
import { Divider } from "@mui/material";
import { Icon } from "components/simple";

import styles from "./styles.module.scss";

const StepsToGet = ({ steps, onClose }) => {
  return (
    <section>
      <div className={styles.topContainer}>
        <span className={styles.title}>Steps to get {steps.title}</span>
        <span onClick={onClose}>
          <Icon.Close width={24} height={24} className={styles.closeIcon} />
        </span>
      </div>
      <Divider />
      <div className={styles.stepsContainer}>
        {Array.isArray(steps.steps)
          ? steps.steps.map((step, i) => (
              <span key={i} className={styles.step}>{`${i + 1}. ${step}`}</span>
            ))
          : steps.steps}
      </div>
    </section>
  );
};

export default memo(StepsToGet);
