import React, { useCallback, useState } from "react";
import { Button, Input } from "components/simple";

import styles from "./styles.module.scss";
import StepsToGet from "../StepsToGet";

const steps = {
  applicationID: {
    title: "Application ID",
    steps: [
      "Log into your Azure Portal and navigate to the Azure Active Directory service.",
      "Select App registrations and then click on New registration.",
      'Enter "AccuKnox" or a descriptive name in the "Name" field and take note of it; it will be used again.',
      'Leave the "Supported account types" default: "Accounts in this organizational directory only (your directory name)".',
      "Click on Register, Copy the Application ID and paste it."
    ]
  },
  keyValue: {
    title: "Key Value",
    steps: [
      "Click on Certificates & secrets.",
      "Under Client secrets, click on New client secret.",
      'Enter a description (i.e. AccuKnox-2022) and select Expires "Never" or the maximum date range.',
      "Click on Add, the client secret value will only be visible once, copy and paste it."
    ]
  },
  subscriptionID: {
    title: "Subscription ID",
    steps: [
      "Navigate to Subscriptions and select the relevant Subscription ID, copy and paste the ID.",
      'Click on "Access Control (IAM)" and go to the Role assignments tab.',
      'Click on "Add", then "Add role assignment", In the "Role" drop-down, select "Security Reader".',
      'Leave the "Assign access to" default value.',
      'In the "Select" drop-down, type the name of the app registration (e.g. "AccuKnox") you created and select it.',
      'Save the role assignment, repeat the process for the role "Log Analytics Reader".'
    ]
  },
  directoryID: {
    title: "Directory ID",
    steps: [
      "Navigate to App registrations.",
      "Locate the app you created and click on it.",
      'Copy the "Directory (tenant) ID" and paste it. '
    ]
  }
};

const AzureForm = ({ onBackHandler, onCancelHandler }) => {
  const [getSteps, setGetSteps] = useState("");
  const [state, setState] = useState({
    applicationID: "",
    keyValue: "",
    subscriptionID: "",
    directoryID: ""
  });

  const onSave = e => {
    e.preventDefault();
  };

  const closeSteps = useCallback(() => {
    setGetSteps("");
  }, [setGetSteps]);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={onSave}>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Application ID*</span>
            <span className={styles.showSteps} onClick={() => setGetSteps("applicationID")}>
              Show steps
            </span>
          </div>
          <Input
            type="text"
            placeholder="Paste the Application ID*"
            value={state.applicationID}
            onChange={val => handleChange(val.target.value, "applicationID")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Key Value*</span>
            <span className={styles.showSteps} onClick={() => setGetSteps("keyValue")}>
              Show steps
            </span>
          </div>
          <Input
            type="text"
            placeholder="Paste the Key Value*"
            value={state.keyValue}
            onChange={val => handleChange(val.target.value, "keyValue")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Subscription ID*</span>
            <span className={styles.showSteps} onClick={() => setGetSteps("subscriptionID")}>
              Show steps
            </span>
          </div>
          <Input
            type="text"
            placeholder="Paste the Subscription ID*"
            value={state.subscriptionID}
            onChange={val => handleChange(val.target.value, "subscriptionID")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Directory ID*</span>
            <span className={styles.showSteps} onClick={() => setGetSteps("directoryID")}>
              Show steps
            </span>
          </div>
          <Input
            type="text"
            placeholder="Paste the Directory ID*"
            value={state.directoryID}
            onChange={val => handleChange(val.target.value, "directoryID")}
            required
          />
        </div>
        <div className={styles.btnContainer}>
          <Button variant="outline" onClick={onBackHandler}>
            Back
          </Button>
          <Button variant="outline" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button type="submit">Connect</Button>
        </div>
      </form>
      {getSteps && (
        <div className={styles.stepsContainer}>
          <StepsToGet steps={steps[getSteps]} onClose={closeSteps} />
        </div>
      )}
    </div>
  );
};

export default AzureForm;
