import React, { useState, useEffect } from "react";

import { Button, Title, Modal, SelectAsync } from "components/simple";
import { getBaselines } from "store/entities/assets/service";

import styles from "./styles.module.scss";

const AddBaselineModal = ({ open, close, data, onAddFiles }) => {
  const [items, setItems] = useState([]);

  const onSave = () => {
    onAddFiles(items);
  };

  useEffect(() => {
    if (open && data?.length) {
      setItems(data);
    }
  }, [open]);

  useEffect(() => {
    if (open) {
      setItems(data);
    }
    return () => setItems([]);
  }, [open]);

  const onBack = () => close();

  const requst = async (search, page) => {
    try {
      const res = await getBaselines({ search, page }).then(r => r.json());
      const listOptions = res?.results?.map(item => ({ value: item?.id, label: item?.name }));
      return { options: listOptions, next: res?.next };
    } catch (e) {
      // return console.log("err", e);
    }
  };

  const loadOptions = async (search, prevOptions, { page }) => {
    const { options, next } = await requst(search, page);
    const hasMore = !search ? Boolean(next) : false;

    return {
      options,
      hasMore,
      additional: {
        page: page + 1
      }
    };
  };

  return (
    <Modal isOpen={open} toggle={close}>
      <Title>Assign Baseline</Title>
      <div className={styles.content}>
        <div className={styles.form}>
          <SelectAsync
            containerClass={styles.selectPanel}
            onChange={val => setItems(val)}
            value={items}
            isClearable
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            cacheOptions
            defaultOptions
            loadOptions={loadOptions}
            onInputChange={() => null}
            additional={{
              page: 1
            }}
          />
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button onClick={onBack} variant="outline" className={styles.modalButton}>
          Back
        </Button>
        <Button onClick={onSave} className={styles.modalButton}>
          Save
        </Button>
      </div>
    </Modal>
  );
};

export default AddBaselineModal;
