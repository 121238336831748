/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, patch, put, post, remove } from "api/base";

export const getTargets = fields => {
  const { page, pageSize, search, type, tag, ordering } = fields;

  let url = `${API}/targets?page=${page + 1}&page_size=${pageSize}`;
  if (search) {
    url += `&search=${encodeURIComponent(search)}`;
  }
  if (type) {
    url += `&target_type=${encodeURIComponent(type)}`;
  }
  if (tag) {
    url += `&tag_id=${encodeURIComponent(tag)}`;
  }
  if (ordering) {
    url += `&ordering=${encodeURIComponent(ordering)}`;
  }

  return get(url, {
    ...authorization(url),
    ...authorizationTenant()
  });
};

export const getTargetsMini = fields => {
  const { page, pageSize, search } = fields;

  return get(`${API}/targets?page=${page + 1 || 1}&page_size=${pageSize}&search=${search ?? ""}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTarget = id => {
  const url = `${API}/targets/${id}`;

  return get(url, {
    ...authorization(url),
    ...authorizationTenant()
  });
};

export const getTargetTypes = () => {
  return get(`${API}/targets/data-types`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTargetTags = () => {
  return get(`${API}/tags?data_type=Divy`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getScheduleData = () => {
  return get(`${API}/targets/schedule-data`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTargetGroups = () => {
  return get(`${API}/groups`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const patchGroup = ({ groupId, data }) => {
  return patch(`${API}/groups/${encodeURIComponent(groupId)}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const addToGroup = data => {
  return post(`${API}/targets/add-to-group`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const postTarget = data => {
  return post(`${API}/targets`, data, {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const putTarget = data => {
  const { id, data: targetData } = data;

  return put(`${API}/targets/${encodeURIComponent(id)}`, targetData, {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteTarget = id => {
  return remove(
    `${API}/targets/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getAsset = id => {
  const url = `${API}/assets-mini/${id}`;

  return get(url, {
    ...authorization(url),
    ...authorizationTenant()
  });
};

export const getAssets = search => {
  let url = `${API}/assets-mini`;
  if (search && search !== "") {
    url += `?search=${encodeURIComponent(search)}`;
  }

  return get(url, {
    ...authorization(url),
    ...authorizationTenant()
  });
};

export const getPlaybooks = () => {
  const url = `${API}/playbook`;

  return get(url, {
    ...authorization(url),
    ...authorizationTenant()
  });
};

export const getMinions = () => {
  const url = `${API}/minions`;

  return get(url, {
    ...authorization(url),
    ...authorizationTenant()
  });
};

export const getExpectedVaribles = params => {
  const { playbook_id } = params;
  let url = `${API}/expected-variable`;

  url += `${playbook_id ? `?playbook_id=${playbook_id}` : ""}`;
  return get(url, {
    ...authorization(url),
    ...authorizationTenant()
  });
};

export const getVariablesMini = () => {
  const url = `${API}/variable-mini`;

  return get(url, {
    ...authorization(url),
    ...authorizationTenant()
  });
};

export const postPlaybookConfiguration = data => {
  return post(`${API}/playbook-configuration`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};
