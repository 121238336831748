/* eslint-disable camelcase */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { routes } from "router";

import {
  getAsset,
  setAssetTag,
  setAssetBaseline,
  getAssetDataListTypes,
  getAssetDataListType
} from "store/entities/assets/actions";
import { editFindingAsset } from "store/entities/vulnerabilities/actions";

import { assetDetailsSelector, assetDataListSelector } from "store/entities/assets/selectors";
import { getGroups, getTickets, getFindings, getMonitors } from "store/entities/assets/service";
import { getDynamicSelect } from "store/entities/vulnerabilities/service";

import { createLoadingSelector } from "store/entities/loading/selector";

import {
  Title,
  ContentContainer,
  PieChart,
  Loader,
  ActionButton,
  Icon,
  DataListServer,
  DataList,
  Select
} from "components/simple";

import DetailsBox from "./DetailsBox";
import AddTagModal from "./AddTagModal";
import DataListModal from "./DataListModal";

import AddBaselineModal from "./AddBaselineModal";

import styles from "./styles.module.scss";

const AssetsDetails = ({ history }) => {
  const dispatch = useDispatch();

  const [openModalTag, setOpenModalTag] = useState(false);
  const [openModalBaseline, setOpenModalBaseline] = useState(false);
  const [openModalDataList, setOpenModalDataList] = useState(false);

  const [dataList, setDataList] = useState([]);
  const [dataListSearch, setDataListSearch] = useState("");
  const [searchTextLinkedAssets, setSearchTextLinkedAssets] = useState("");

  const params = useParams();
  const id = params?.id;

  const selectTenant = useSelector(state => state.users.selectTenant);
  const isLoading = useSelector(state => createLoadingSelector([getAsset.type])(state));
  const isDataListLoading = useSelector(state =>
    createLoadingSelector([getAssetDataListTypes.type])(state)
  );
  const isDataListTypeLoading = useSelector(state =>
    createLoadingSelector([getAssetDataListType.type])(state)
  );

  const { vulnerabilities, baselines, details, tags, compliances, linked_assets } = useSelector(
    assetDetailsSelector
  );
  const assetDataList = useSelector(assetDataListSelector);
  const assetDataListType = useSelector(state => state.assets.assetDataListType);

  useEffect(() => {
    dispatch(getAsset({ id }));
    dispatch(getAssetDataListTypes(id));
  }, [id, selectTenant]);

  useEffect(() => {
    if (assetDataList.length) {
      setDataList(assetDataList);
    }
  }, [assetDataList]);

  const closeModalTag = () => {
    setOpenModalTag(false);
  };

  const closeModalBaseline = () => {
    setOpenModalBaseline(false);
  };

  const onAddTags = res => {
    dispatch(setAssetTag({ id, data: { ids: res?.map(item => item?.value) } }));
    closeModalTag();
  };

  const onAddBaseline = res => {
    dispatch(setAssetBaseline({ id, data: { ids: res?.map(item => item?.value) } }));
    closeModalBaseline();
  };

  const dataListClickHandler = type => {
    setOpenModalDataList(true);
    dispatch(getAssetDataListType({ id, type }));
  };

  const searchInputChangeHandler = value => {
    setDataListSearch(value);

    if (value === "") {
      setDataList(assetDataList);
      return;
    }
    const newList = assetDataList.filter(el =>
      el.title.toLowerCase().includes(value.toLowerCase())
    );
    setDataList(newList);
  };

  const closeModalDataList = () => {
    setOpenModalDataList(false);
  };

  // select request
  const [optionsStatus, setOptionsStatus] = useState([]);
  const [optionsIgnored, setOptionsIgnored] = useState([]);
  const selectors = ["status", "ignored"];

  const loadOptionsRequest = async field => {
    try {
      const res = await getDynamicSelect({ field, page: 1 });
      const listOptions = Object.entries(res?.results).map(([key, val]) => {
        return { value: key, label: val };
      });
      if (field === "status") {
        setOptionsStatus(listOptions);
      } else {
        setOptionsIgnored(listOptions);
      }
    } catch (e) {
      // return console.log("err", e);
    }
  };

  useEffect(() => {
    selectors.map(s => loadOptionsRequest(s));
  }, []);

  const onSelectChange = (v, name, elemId, callback) => {
    const data = {
      [name]: v,
      id: elemId
    };
    dispatch(editFindingAsset({ data, findingId: id, callback }));
  };

  const linkedAssetsList = React.useMemo(() => {
    if (searchTextLinkedAssets === "") {
      return linked_assets;
    }
    const newList = linked_assets.filter(el =>
      el.title.toLowerCase().includes(searchTextLinkedAssets.toLowerCase())
    );
    return newList;
  }, [linked_assets, searchTextLinkedAssets]);

  const renderFindingRowEl = (list, _, callback) => {
    return list.map(({ ignored, id: findingId, name, status }) => {
      const ignoredString = ignored.toString();
      const ignoredValue = ignoredString
        ? ignoredString[0].toUpperCase() + ignoredString.slice(1)
        : "";
      return (
        <li key={findingId} className={styles.tableRow}>
          <div className={styles.titleCell}>
            <Link to={`/vulnerabilities/details/${findingId}`}>{name}</Link>
          </div>
          <Select
            options={optionsStatus}
            value={{ label: status, value: status }}
            onChange={o => onSelectChange(o.value, "status", findingId, callback)}
            placeholder="Status"
            className={styles.selectCell}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuShouldBlockScroll
            tableSelect
          />
          <Select
            options={optionsIgnored}
            value={{
              label: ignoredValue,
              value: ignoredValue
            }}
            onChange={o => onSelectChange(o.value, "ignored", findingId, callback)}
            placeholder="Ignored"
            className={styles.selectCell}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuShouldBlockScroll
            tableSelect
          />
        </li>
      );
    });
  };

  return (
    <div className={styles.container}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles.grid}>
            <DetailsBox data={details} />
            <ContentContainer className={styles.wrapper}>
              <div className={styles.head}>
                <Title>Tags</Title>
                <ActionButton type="add" onClick={() => setOpenModalTag(true)} />
              </div>
              <br />
              <div className={styles.tagsList}>
                {tags?.map((item, index) => (
                  <div key={index} className={styles.tag}>
                    {item?.label}
                  </div>
                ))}
              </div>
            </ContentContainer>

            <ContentContainer className={styles.wrapper}>
              <div className={styles.head}>
                <Title>Vulnerabilities</Title>
                <div className={styles.btn} onClick={() => history.push(routes.vulnerabilities)}>
                  <div className={styles.iconBtn}>
                    <Icon.ArrowBack color="#2B3858" />
                  </div>
                </div>
              </div>
              <br />
              <PieChart
                data={vulnerabilities}
                className={styles.chart}
                onClick={v => {
                  const val = vulnerabilities[v]?.key;
                  const res = { risk_factor: { value: val, label: val } };
                  history.push(
                    `${routes.vulnerabilities}?filters=${JSON.stringify(
                      res
                    )}&asset=${JSON.stringify({ label: details?.name, value: id })}`
                  );
                }}
              />
            </ContentContainer>
            <ContentContainer className={styles.wrapper}>
              <div className={styles.head}>
                <Title>Baselines</Title>
                <ActionButton type="add" onClick={() => setOpenModalBaseline(true)} />
              </div>
              <br />
              <PieChart
                data={compliances}
                className={styles.chart}
                onClick={v => history.push(`${routes.baselines}?filter=${compliances[v]?.key}`)}
              />
            </ContentContainer>
          </div>

          <div className={styles.grid}>
            <DataListServer
              title="Group membership"
              name="group"
              request={pr => getGroups({ ...pr, id })}
            />
            <DataList
              list={dataList}
              title="DataLists"
              name="dataLists"
              searchText={dataListSearch}
              onClick={e => dataListClickHandler(e.id)}
              onSearch={searchInputChangeHandler}
              isLoading={isDataListLoading}
            />
            <DataListServer
              title="Tickets"
              name="tickets"
              request={pr => getTickets({ ...pr, id })}
            />

            <DataListServer
              title="Monitors"
              name="monitors"
              request={pr => getMonitors({ ...pr, id })}
            />
            <DataListServer
              title="Findings"
              name="findings"
              request={pr => getFindings({ ...pr, id })}
              render={renderFindingRowEl}
              headers={["Title", "Status", "Ignored"]}
            />
            <DataList
              list={linkedAssetsList}
              title="Linked Assets"
              name="linked_assets"
              searchText={searchTextLinkedAssets}
              onSearch={txt => setSearchTextLinkedAssets(txt)}
              onSelectChange={onSelectChange}
              onClick={e => {
                history.push(`${routes.dataStructureAssetsDetails}/${e?.id}`);
              }}
            />
          </div>
          <AddTagModal
            open={openModalTag}
            close={closeModalTag}
            onAddFiles={onAddTags}
            data={tags}
          />

          <AddBaselineModal
            open={openModalBaseline}
            close={closeModalBaseline}
            onAddFiles={onAddBaseline}
            data={baselines}
          />

          <DataListModal
            open={openModalDataList}
            close={closeModalDataList}
            data={assetDataListType}
            isLoading={isDataListTypeLoading}
          />
        </>
      )}
    </div>
  );
};

AssetsDetails.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired
};

export default AssetsDetails;
