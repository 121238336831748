export const stylesSelect = {
  control: (provided, state) => {
    const { menuIsOpen } = state.selectProps;

    return {
      ...provided,
      width: "100%",
      minHeight: "40px",
      border: menuIsOpen ? "1px solid #d83e3c" : "1px solid #d9dfea",
      borderRadius: "10px",
      boxShadow: menuIsOpen ? "0px 0px 33px rgba(0, 0, 0, 0.06)" : "none",
      backgroundColor: "#ffffff",
      paddingLeft: "18px",
      zIndex: 1,
      display: "flex",
      alignItems: "center",
      color: "#2b3858",

      ":hover": {
        borderColor: menuIsOpen ? "#ca2149" : "#d9dfea"
      }
    };
  },

  menu: provided => {
    return {
      ...provided,
      backgroundColor: "#ffffff",
      borderRadius: "10px",
      boxShadow: "none",
      zIndex: 999
    };
  },

  menuList: provided => ({
    ...provided,
    backgroundColor: "#ffffff",
    borderRadius: "10px",
    padding: "8px",
    border: "1px solid #d9dfea",
    zIndex: 10000
  }),

  option: (provided, { isDisabled, isFocused, isSelected }) => {
    return {
      ...provided,
      cursor: isDisabled ? "not-allowed" : "pointer",
      backgroundColor: isFocused || isSelected ? "#f4f5fa" : "#ffffff",
      color: "#2b3858",
      fontSize: "14px",
      lineHeight: "16px",
      fontWeight: isSelected ? "600" : "normal",
      borderRadius: "7px",
      paddingLeft: "10px",
      marginBottom: "1px",

      ":active": {
        backgroundColor: "#f4f5fa"
      }
    };
  },

  input: styles => ({
    ...styles,
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px",
    lineHeight: "17px",
    fontWeight: "600",
    color: "#2b3858"
  }),

  singleValue: styles => ({
    ...styles,
    width: "100%",
    textOverflow: "ellipsis",
    position: "absolute",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "600",
    color: "#2b3858"
  }),

  placeholder: styles => ({
    ...styles,
    fontFamily: "PoppinsMedium",
    fontSize: "14px",
    lineHeight: "16px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: "#2B3858",
    margin: "0"
  }),

  valueContainer: styles => ({ ...styles, padding: "0", overflowY: "auto", maxHeight: "100px" }),

  indicatorSeparator: () => ({
    backgroundColor: "transparent"
  }),

  noOptionsMessage: styles => ({
    ...styles,
    fontFamily: "PoppinsMedium",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "inherit"
  }),

  dropdownIndicator: (styles, state) => {
    const { menuIsOpen, required } = state.selectProps;
    let color = "#2b3858";
    if (menuIsOpen || required) {
      color = "#d83e3c";
    }

    return {
      ...styles,
      transition: "0.5s",
      transform: menuIsOpen ? "rotate(180deg)" : "rotate(0deg)",
      marginRight: "14px",

      path: {
        stroke: color
      }
    };
  }
};
