export const SET_PARAMS_TARGETS = "SET_PARAMS_TARGETS";

// Get TARGETS
export const GET_TARGETS = "GET_TARGETS_REQUEST";
export const GET_TARGETS_SUCCESS = "GET_TARGETS_SUCCESS";
export const GET_TARGETS_FAILED = "GET_TARGETS_FAILED";

// Get Targets Mini
export const GET_TARGETS_MINI = "GET_TARGETS_MINI_REQUEST";
export const GET_TARGETS_MINI_SUCCESS = "GET_TARGETS_MINI_SUCCESS";
export const GET_TARGETS_MINI_FAILED = "GET_TARGETS_MINI_FAILED";

// Get TARGET
export const GET_TARGET = "GET_TARGET_REQUEST";
export const GET_TARGET_SUCCESS = "GET_TARGET_SUCCESS";
export const GET_TARGET_FAILED = "GET_TARGET_FAILED";

// Get TARGET TYPES
export const GET_TARGET_TYPES = "GET_TARGET_TYPES";
export const GET_TARGET_TYPES_SUCCESS = "GET_TARGET_TYPES_SUCCESS";
export const GET_TARGET_TYPES_FAILED = "GET_TARGET_TYPES_FAILED";

// Get TARGET TAGS
export const GET_TARGET_TAGS = "GET_TARGET_TAGS";
export const GET_TARGET_TAGS_SUCCESS = "GET_TARGET_TAGS_SUCCESS";
export const GET_TARGET_TAGS_FAILED = "GET_TARGET_TAGS_FAILED";

// Get SCHEDULE DATA
export const GET_SCHEDULE_DATA = "GET_SCHEDULE_DATA";
export const GET_SCHEDULE_DATA_SUCCESS = "GET_SCHEDULE_DATA_SUCCESS";
export const GET_SCHEDULE_DATA_FAILED = "GET_SCHEDULE_DATA_FAILED";

// Get GROUPS
export const GET_TARGET_GROUPS = "GET_TARGET_GROUPS";
export const GET_TARGET_GROUPS_SUCCESS = "GET_TARGET_GROUPS_SUCCESS";
export const GET_TARGET_GROUPS_FAILED = "GET_TARGET_GROUPS_FAILED";

// Get ASSETS
export const GET_ASSETS = "GET_ASSETS";
export const GET_ASSETS_SUCCESS = "GET_ASSETS_SUCCESS";
export const GET_ASSETS_FAILED = "GET_ASSETS_FAILED";

// Get PLAYBOOKS
export const GET_PLAYBOOKS = "GET_PLAYBOOKS";
export const GET_PLAYBOOKS_SUCCESS = "GET_PLAYBOOKS_SUCCESS";
export const GET_PLAYBOOKS_FAILED = "GET_PLAYBOOKS_FAILED";

// Get MINIONS
export const GET_MINIONS = "GET_MINIONS";
export const GET_MINIONS_SUCCESS = "GET_MINIONS_SUCCESS";
export const GET_MINIONS_FAILED = "GET_MINIONS_FAILED";

// Get EXPECTED VARIABLES
export const GET_EXPECTED_VARIABLES = "GET__EXPECTED_VARIABLES";
export const GET_EXPECTED_VARIABLES_SUCCESS = "GET_EXPECTED_VARIABLES_SUCCESS";
export const GET_EXPECTED_VARIABLES_FAILED = "GET_EXPECTED_VARIABLES_FAILED";

// Get VARIABLES_MINI
export const GET_VARIABLES_MINI = "GET_VARIABLES_MINI";
export const GET_VARIABLES_MINI_SUCCESS = "GET_VARIABLES_MINI_SUCCESS";
export const GET_VARIABLES_MINI_FAILED = "GET_VARIABLES_MINI_FAILED";

// MODAL
// Create Target
export const CREATE_TARGET_MODAL = "CREATE_TARGET_MODAL_TARGETS";

// Add to group
export const ADD_TO_GROUP_MODAL = "ADD_TO_GROUP_MODAL_TARGETS";

// Delete Target
export const DELETE_TARGET_MODAL = "DELETE_TARGET_MODAL_TARGETS";

// ACTIONS
// Create target
export const CREATE_TARGET = "CREATE_TARGET_REQUEST";
export const CREATE_TARGET_SUCCESS = "CREATE_TARGET_SUCCESS";
export const CREATE_TARGET_FAILED = "CREATE_TARGET_FAILED";

// Edit target
export const EDIT_TARGET = "EDIT_TARGET_REQUEST";
export const EDIT_TARGET_SUCCESS = "EDIT_TARGET_SUCCESS";
export const EDIT_TARGET_FAILED = "EDIT_TARGET_FAILED";

// Add to group
export const ADD_TO_GROUP = "ADD_TO_GROUP_REQUEST";
export const ADD_TO_GROUP_SUCCESS = "ADD_TO_GROUP_SUCCESS";
export const ADD_TO_GROUP_FAILED = "ADD_TO_GROUP_FAILED";

// Add to new group
export const ADD_TO_NEW_GROUP = "ADD_TO_NEW_GROUP_REQUEST";
export const ADD_TO_NEW_GROUP_SUCCESS = "ADD_TO_NEW_GROUP_SUCCESS";
export const ADD_TO_NEW_GROUP_FAILED = "ADD_TO_NEW_GROUP_FAILED";

// Delete targets
export const DELETE_TARGETS = "DELETE_TARGETS_REQUEST";
export const DELETE_TARGETS_SUCCESS = "DELETE_TARGETS_SUCCESS";
export const DELETE_TARGETS_FAILED = "DELETE_TARGETS_FAILED";

// Save playbook configuration
export const SAVE_PLAYBOOK_CONFIG = "SAVE_PLAYBOOK_CONFIG";
export const SAVE_PLAYBOOK_CONFIG_SUCCESS = "SAVE_PLAYBOOK_CONFIG_SUCCESS";
export const SAVE_PLAYBOOK_CONFIG_FAILED = "SAVE_PLAYBOOK_CONFIG_FAILED";
