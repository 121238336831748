import { Button, Input, Select } from "components/simple";
import React, { useCallback, useState } from "react";
import StepsToGet from "../StepsToGet";

import styles from "./styles.module.scss";

const steps = {
  secretAccessKey: {
    title: "Access Key",
    steps: (
      <div>
        <div className={styles.stepTitle}>Via console:</div>
        <p className={styles.step}>
          1. Use your AWS account ID or account alias, your IAM user name, and your password to sign
          in to the IAM console.
        </p>
        <p className={styles.step}>
          2. In the navigation bar on the upper right, choose your user name, and then choose
          Security credentials.
        </p>
        <p className={styles.step}>
          3. Expand the Access keys (access key ID and secret access key) section.
        </p>
        <p className={styles.step}>
          4. Do any of the following: To create an access key, choose Create New Access Key.
        </p>
        <div className={styles.stepTitle}>Via AWS CLI:</div>
        <p className={styles.step}>To create an access key:</p>
        <p className={styles.step}>aws iam create-access-key </p>
        <div className={styles.stepTitle}>Permissions:</div>
        <p className={styles.step}>Grant the ReadOnlyAccess policy to your user or role.</p>
      </div>
    )
  }
};

const AccessKeys = ({ onBackHandler, onCancelHandler }) => {
  const [getSteps, setGetSteps] = useState("");
  const [state, setState] = useState({
    accessKeyId: "",
    secretAccessKey: "",
    region: ""
  });

  const onSave = e => {
    e.preventDefault();
  };

  const closeSteps = useCallback(() => {
    setGetSteps("");
  }, [setGetSteps]);

  const handleChange = (val, name) => {
    setState({ ...state, [name]: val });
  };

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={onSave}>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Access Key ID*</span>
          </div>
          <Input
            type="text"
            placeholder="Enter the Access Key ID*"
            value={state.accessKeyId}
            onChange={val => handleChange(val.target.value, "accessKeyId")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Secret Access Key*</span>
            <span className={styles.showSteps} onClick={() => setGetSteps("secretAccessKey")}>
              Show steps
            </span>
          </div>
          <Input
            type="text"
            placeholder="Enter the Secret Access Key"
            value={state.secretAccessKey}
            onChange={val => handleChange(val.target.value, "secretAccessKey")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Access Key ID*</span>
          </div>
          <Input
            type="text"
            placeholder="Enter the Access Key ID*"
            value={state.accessKeyId}
            onChange={val => handleChange(val.target.value, "accessKeyId")}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.labelWrapper}>
            <span className={styles.label}>Region*</span>
          </div>
          <Select
            type="text"
            placeholder="Select Region"
            value={state.region}
            options={[]}
            onChange={val => handleChange(val.target.value, "accessKeyId")}
          />
        </div>
        <div className={styles.btnContainer}>
          <Button variant="outline" onClick={onBackHandler}>
            Back
          </Button>
          <Button variant="outline" onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button type="submit">Connect</Button>
        </div>
      </form>
      {getSteps && (
        <div className={styles.stepsContainer}>
          <StepsToGet steps={steps[getSteps]} onClose={closeSteps} />
        </div>
      )}
    </div>
  );
};

export default AccessKeys;
