import React, { useMemo, useState } from "react";
import { Divider, Switch } from "@mui/material";

import styles from "./styles.module.scss";
import { DatePicker, Select } from "components/simple";
import { TimeSelect } from "screens/Targets/CreateTargetModal/TimeSelect";
import { WeekDaySelect } from "screens/Targets/CreateTargetModal/WeekDaySelect";
import { useSelector } from "react-redux";

const SoarcastSchedule = ({ schedule, setSchedule }) => {
  const { scheduleData } = useSelector(state => state.targets);
  const [scheduleEnabled, setScheduleEnabled] = useState(false);

  const repeatOptions = useMemo(() => {
    if (!scheduleData || !scheduleData.repeat_every) {
      return [];
    }

    return scheduleData.repeat_every.map(item => ({ value: item, label: item }));
  }, [scheduleData]);

  const freqOptions = useMemo(() => {
    if (!scheduleData || !scheduleData.frequency) {
      return [];
    }

    return scheduleData.frequency.map(item => ({ value: item[0], label: item[1] }));
  }, [scheduleData]);

  const timezoneOptions = useMemo(() => {
    if (!scheduleData || !scheduleData.timezones) {
      return [];
    }

    return scheduleData.timezones.map(item => ({ value: item, label: item }));
  }, [scheduleData]);

  return (
    <div className={styles.container}>
      <div className={styles.switchBoxHeader}>
        <Switch checked={scheduleEnabled} onChange={(e, value) => setScheduleEnabled(value)} />
        <div className={styles.switchBoxLabel}>Schedule</div>
      </div>
      {scheduleEnabled && (
        <div className={styles.scheduleContainer}>
          <div className={styles.inputsBlock}>
            <Select
              containerClass={styles.blockInput}
              label="Frequency"
              name="frequency"
              options={freqOptions}
              value={schedule?.frequency}
              onChange={setSchedule}
            />
            <DatePicker
              className={styles.blockInput}
              inputClassname={styles.dateInput}
              label="Date"
              value={schedule?.date}
              onChange={val => setSchedule(val.toLocaleDateString(), "date")}
            />
            <Select
              containerClass={styles.blockInput}
              label="Timezone"
              name="timezone"
              options={timezoneOptions}
              value={schedule?.timezone}
              onChange={setSchedule}
            />
            <TimeSelect
              className={styles.blockInput}
              label="Start"
              value={schedule?.start}
              onChange={val => setSchedule(val, "start")}
            />
            <Select
              containerClass={styles.blockInput}
              label="Repeat every"
              name="repeatEvery"
              options={repeatOptions}
              value={schedule?.repeatEvery}
              onChange={setSchedule}
            />
          </div>
          <div className={styles.weekDayContainer}>
            <WeekDaySelect
              label="Repeat on"
              value={schedule?.repeatOn || []}
              onValueChange={val => setSchedule(val, "repeatOn")}
            />
          </div>
          <div className={styles.summaryTitle}>Summary</div>
          <div className={styles.summary}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Tellus nibh magna curabitur
            accumsan aliquam ornare habitant viverra. Ipsum.
          </div>
          <Divider />
        </div>
      )}
    </div>
  );
};

export default SoarcastSchedule;
