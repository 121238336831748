import { combineReducers } from "@reduxjs/toolkit";

import { logoutSuccess } from "../entities/users/actions";

import users from "../entities/users/reducer";
import vulnerabilities from "../entities/vulnerabilities/reducer";
import targets from "../entities/targets/reducer";
import labels from "../entities/labels/reducer";
import assets from "../entities/assets/reducer";
import tickets from "../entities/tickets/reducer";
import baseline from "../entities/baseline/reducer";
import reports from "../entities/reports/reducer";
import settings from "../entities/settings/reducer";
import tags from "../entities/tags/reducer";
import monitors from "../entities/monitors/reducer";

import auditFiles from "../entities/auditfiles/reducer";

import groups from "../entities/groups/reducer";

import importHistory from "../entities/importHistory/reducer";

import datalist from "../entities/datalist/reducer";
import dashboard from "../entities/dashboard/reducer";

import notifications from "../entities/notifications/reducer";
import loading from "../entities/loading/reducer";
import theme from "../entities/theme/reducer";

import error from "../entities/error/reducer";

const reducers = combineReducers({
  users,
  notifications,
  loading,
  error,
  theme,
  vulnerabilities,
  targets,
  labels,
  assets,
  tickets,
  baseline,
  settings,
  reports,
  datalist,
  importHistory,
  tags,
  monitors,
  groups,
  dashboard,
  auditFiles
});

export default (state, action) => {
  return reducers(action.type === logoutSuccess.type ? undefined : state, action);
};
