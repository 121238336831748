/* eslint-disable */
import React from "react";
import { Switch } from "react-router-dom";

// Routing
import * as routes from "router/routes";

// Screens
import Home from "screens/Home";
import {
  History,
  Tags,
  TagsDetails,
  Groups,
  GroupsDetails,
  Datalist,
  DatalistConfig,
  Assets,
  AssetsDetails,
  Labels,
  Baselines as BaselineDashbord
} from "screens/DataStructure";

import { Vulnerabilities, VulnerabilityDetails } from "screens/Vulnerabilities";
import Baselines from "screens/Baselines";
import FailedBaseline from "screens/Baselines/FailedBaseline";
import CreateBaseline from "screens/Baselines/CreateBaseline";
import BaselineConfiguration from "screens/Baselines/BaselineConfiguration";
import CompareBaselines from "screens/Baselines/CompareBaselines";

import { Targets, TargetsDetails } from "screens/Targets";
import { Tickets, TicketsDetails, CreateTicket } from "screens/Tickets";

import { Reports, ReportsDetails } from "screens/Reports";
import { Notifications, NotificationsDetails } from "screens/Notifications";
// Settings
import Settings from "screens/Settings";
import {
  DataSourceEdit,
  JiraCloud,
  JiraServer,
  Freshservice,
  EmailsDetails,
  TicketTemplateDetails,
  RbacRoleDetails,
  UserProfilesDetails,
  LabelDetails,
  ConnectWise
} from "screens/Settings/Tabs";
// Settings end

import Profile from "screens/Profile";

import { Monitors } from "screens/Monitors";
import MonitorsConfiguration from "screens/Monitors/MonitorsConfiguration";

import PrivateRoute from "components/PrivateRoute";
import CloudAccounts from "screens/CloudAccounts";
import AddAccount from "screens/CloudAccounts/AddAccount";

// Permissions
// import { AccessControlRoute, permissions } from "components/permissions";

const DashboardContent = () => {
  return (
    <Switch>
      <PrivateRoute exact path={routes.home} component={Home} />

      <PrivateRoute exact path={routes.dataStructureHistory} component={History} />
      <PrivateRoute exact path={routes.dataStructureTags} component={Tags} />
      <PrivateRoute exact path={`${routes.dataStructureTagsDetails}/:id`} component={TagsDetails} />
      <PrivateRoute exact path={routes.dataStructureGroups} component={Groups} />
      <PrivateRoute
        exact
        path={`${routes.dataStructureGroupsDetails}/:id`}
        component={GroupsDetails}
      />
      <PrivateRoute exact path={routes.dataStructureDatalist} component={Datalist} />
      <PrivateRoute
        exact
        path={`${routes.dataStructureDatalistCreateTicket}/:ticketConfig_id`}
        component={CreateTicket}
      />
      <PrivateRoute exact path={routes.dataStructureAssets} component={Assets} />
      <PrivateRoute
        exact
        path={`${routes.dataStructureAssetsDetails}/:id`}
        component={AssetsDetails}
      />

      <PrivateRoute exact path={routes.dataStructureLabels} component={Labels} />
      <PrivateRoute exact path={routes.dataStructureBaselines} component={BaselineDashbord} />

      <PrivateRoute exact path={routes.vulnerabilities} component={Vulnerabilities} />
      <PrivateRoute
        exact
        path={`${routes.vulnerabilitiesCreateTicket}/:ticketConfig_id`}
        component={CreateTicket}
      />
      <PrivateRoute
        exact
        path={`${routes.vulnerabilitiesDetails}/:id`}
        component={VulnerabilityDetails}
      />

      <PrivateRoute exact path={routes.baselines} component={Baselines} />

      <PrivateRoute exact path={`${routes.failedBaseline}/:id`} component={FailedBaseline} />
      <PrivateRoute exact path={routes.createBaseline} component={CreateBaseline} />
      <PrivateRoute exact path={`${routes.editBaseline}/:id`} component={CreateBaseline} />
      <PrivateRoute
        exact
        path={`${routes.baselineConfiguration}/:id`}
        component={BaselineConfiguration}
      />
      <PrivateRoute
        exact
        path={`${routes.baselineConfigurationCreateTicket}/:ticketConfig_id/:baseline_id`}
        component={CreateTicket}
      />
      <PrivateRoute exact path={`${routes.compareBaselines}/:ids`} component={CompareBaselines} />

      <PrivateRoute exact path={routes.targets} component={Targets} />
      <PrivateRoute exact path={`${routes.targetsDetails}/:id`} component={TargetsDetails} />

      <PrivateRoute exact path={routes.cloudAccounts} component={CloudAccounts} />
      <PrivateRoute exact path={routes.addCloudAccount} component={AddAccount} />

      <PrivateRoute exact path={routes.tickets} component={Tickets} />
      <PrivateRoute exact path={routes.ticketsDetails} component={TicketsDetails} />
      <PrivateRoute exact path={`${routes.ticketsDetails}/:id`} component={TicketsDetails} />

      <PrivateRoute exact path={routes.reports} component={Reports} />
      <PrivateRoute exact path={routes.reportsDetails} component={ReportsDetails} />
      <PrivateRoute exact path={`${routes.reportsDetails}/:id`} component={ReportsDetails} />

      <PrivateRoute exact path={routes.notifications} component={Notifications} />
      <PrivateRoute
        exact
        path={`${routes.notificationsDetails}/:id`}
        component={NotificationsDetails}
      />

      <PrivateRoute exact path={routes.settings} component={Settings} />
      <PrivateRoute exact path={`${routes.settingsDataSourceEdit}`} component={DataSourceEdit} />
      {/* Setting Integrations */}
      <PrivateRoute exact path={routes.settingsIntegrations} component={Settings} />
      <PrivateRoute exact path={routes.settingsIntegrationsJiraServer} component={JiraServer} />
      <PrivateRoute
        exact
        path={`${routes.settingsIntegrationsJiraServer}/:id`}
        component={JiraServer}
      />
      <PrivateRoute exact path={routes.settingsIntegrationsJiraCloud} component={JiraCloud} />
      <PrivateRoute
        exact
        path={`${routes.settingsIntegrationsJiraCloud}/:id`}
        component={JiraCloud}
      />
      <PrivateRoute exact path={routes.settingsIntegrationsFreshservice} component={Freshservice} />
      <PrivateRoute
        exact
        path={`${routes.settingsIntegrationsFreshservice}/:id`}
        component={Freshservice}
      />
      <PrivateRoute
        exact
        path={routes.settingsIntegrationsEmailsDetails}
        component={EmailsDetails}
      />
      <PrivateRoute
        exact
        path={`${routes.settingsIntegrationsEmailsDetails}/:id`}
        component={EmailsDetails}
      />

      <PrivateRoute exact path={routes.settingsIntegrationsConnectWise} component={ConnectWise} />
      <PrivateRoute
        exact
        path={`${routes.settingsIntegrationsConnectWise}/:id`}
        component={ConnectWise}
      />

      {/* Setting Integrations End */}

      <PrivateRoute exact path={routes.settingsTicketTemplates} component={Settings} />
      <PrivateRoute
        exact
        path={routes.settingsTicketTemplatesDetails}
        component={TicketTemplateDetails}
      />
      <PrivateRoute
        exact
        path={`${routes.settingsTicketTemplatesDetails}/:id`}
        component={TicketTemplateDetails}
      />

      <PrivateRoute exact path={routes.settingsRbac} component={Settings} />
      <PrivateRoute exact path={routes.settingsRbacRoleDetails} component={RbacRoleDetails} />
      <PrivateRoute
        exact
        path={`${routes.settingsRbacRoleDetails}/:id`}
        component={RbacRoleDetails}
      />

      <PrivateRoute exact path={routes.settingsLabels} component={Settings} />
      <PrivateRoute exact path={routes.settingsLabelsDetails} component={LabelDetails} />
      <PrivateRoute exact path={`${routes.settingsLabelsDetails}/:id`} component={LabelDetails} />

      <PrivateRoute exact path={routes.settingsUserProfiles} component={Settings} />
      <PrivateRoute
        exact
        path={`${routes.settingsUserProfilesDetails}`}
        component={UserProfilesDetails}
      />
      <PrivateRoute
        exact
        path={`${routes.settingsUserProfilesDetails}/:id`}
        component={UserProfilesDetails}
      />

      <PrivateRoute exact path={routes.profile} component={Profile} />

      <PrivateRoute exact path={routes.monitors} component={Monitors} />
      <PrivateRoute
        exact
        path={`${routes.monitorsConfiguration}`}
        component={MonitorsConfiguration}
      />
      <PrivateRoute
        exact
        path={`${routes.monitorsConfiguration}/:id`}
        component={MonitorsConfiguration}
      />
    </Switch>
  );
};

export default DashboardContent;
