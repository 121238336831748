import React from "react";
import Icon from "components/simple/Icon";
import styles from "./styles.module.scss";

const FileUploader = ({ onChange, value, className }) => {
  return (
    <>
      <label htmlFor="uploadFile">
        <div className={`${styles.box} ${className}`}>
          {!value ? (
            <>
              <Icon.Download />
              <span>Drop Files here to upload or click to upload</span>
            </>
          ) : (
            <span>{value.name}</span>
          )}
        </div>
      </label>
      <input type="file" hidden onChange={onChange} id="uploadFile" />
    </>
  );
};

export default FileUploader;
