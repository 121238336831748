import { createDraftSafeSelector } from "@reduxjs/toolkit";
import { formatDate } from "helper/DateLib";

const templates = state => state.settings.templates;
const template = state => state.settings.template;
const templatesDataTypes = state => state.settings.templatesDataTypes;

const roles = state => state.settings.roles;
const role = state => state.settings.role;
const users = state => state.settings.users;
const permissions = state => state.settings.permissions;
const s3Profile = state => state.settings?.s3Profile;

const jiraCloud = state => state.settings?.jiraCloud;
const jiraServer = state => state.settings?.jiraServer;
const freshservice = state => state.settings?.freshservice;
const emailProfile = state => state.settings?.emailProfile;
const connectWise = state => state.settings?.connectWise;

const ticketsConfigList = state => state.settings?.ticketsConfigList;
const ticketsConfig = state => state.settings?.ticketsConfig;
const jiraAll = state => state.settings?.jiraAll;
const connectWiseConfig = state => state.settings?.connectWiseConfig;

const freshserviceConfig = state => state.settings?.freshserviceConfig;
const templateListMini = state => state.settings?.templateListMini;
const status = state => state.settings?.status;

const currentJiraCloud = state => state.settings?.currentJiraCloud;
const currentJiraServer = state => state.settings?.currentJiraServer;
const currentFreshservice = state => state.settings?.currentFreshservice;
const currentConnectWise = state => state.settings?.currentConnectWise;

const userProfiles = state => state.settings.userProfiles?.data;
const oneUserProfile = state => state.settings.oneUserProfile;
const groupsForUserProfile = state => state.settings.groupsForUserProfile;

const labels = state => state.settings.labels;

export const templatesTableDataSelector = createDraftSafeSelector(templates, state => {
  if (!state?.results?.length) {
    return { data: [], total: 0 };
  }
  return { data: state.results, total: state?.count };
});

export const templateSelector = createDraftSafeSelector(template, state => {
  return state;
});

export const templatesDataTypeSelect = createDraftSafeSelector(templatesDataTypes, state => {
  if (!state?.length) {
    return [];
  }
  return state.map(({ value, name }) => ({ value, label: name }));
});

export const rolesTableDataSelector = createDraftSafeSelector(roles, state => {
  if (!state?.results?.length) {
    return { data: [], total: 0 };
  }
  const res = state.results.map(e => ({
    ...e,
    permissions: e.visible_permissions.length
  }));
  return { data: res, total: state?.count };
});

export const roleSelector = createDraftSafeSelector(role, state => {
  return {
    ...state,
    permissions: state?.visible_permissions
  };
});

export const usersSelector = createDraftSafeSelector(users, state => {
  if (!state?.results?.length) {
    return [];
  }
  return state.results;
});

export const usersEmailsSelect = createDraftSafeSelector(users, state => {
  if (!state?.results?.length) {
    return [];
  }
  const options = state.results.map(user => ({ value: user.id, label: user.email }));
  return options;
});

export const permissionsSelect = createDraftSafeSelector(permissions, state => {
  if (!Object.keys(state)?.length) {
    return [];
  }
  return Object.entries(state).map(([key, val]) => {
    return { value: key, label: val };
  });
});

export const getS3ProfileSelector = createDraftSafeSelector(s3Profile, state => {
  if (!state) {
    return null;
  }

  const res = {
    aws_s3_access_key: state?.aws_s3_access_key,
    aws_s3_bucket_name: state?.aws_s3_bucket_name,
    id: state?.id
  };
  return res;
});

export const getJiraCloudSelector = createDraftSafeSelector(jiraCloud, state => {
  if (!state?.results?.length) {
    return [];
  }
  const res = state?.results?.map(item => {
    return {
      email: item.email,
      id: item.id,
      is_jira_admin: item.is_jira_admin,
      url: item.url,
      connected: item?.connected,
      last_sync: formatDate(item?.last_sync),
      name: item?.name
    };
  });
  return res;
});

export const getJiraServerSelector = createDraftSafeSelector(jiraServer, state => {
  if (!state?.results?.length) {
    return [];
  }
  const res = state?.results?.map(item => {
    return {
      email: item.email,
      id: item.id,
      is_jira_admin: item.is_jira_admin,
      url: item.url,
      connected: item?.connected,
      last_sync: formatDate(item?.last_sync),
      name: item?.name
    };
  });
  return res;
});

export const getConnectWiseSelector = createDraftSafeSelector(connectWise, state => {
  if (!state?.results?.length) {
    return [];
  }
  const res = state?.results?.map(item => {
    return {
      email: item.email,
      id: item.id,
      is_jira_admin: item.is_jira_admin,
      url: item.url,
      connected: item?.connected,
      last_sync: formatDate(item?.last_sync),
      name: item?.name
    };
  });
  return res;
});

export const getFreshserviceSelector = createDraftSafeSelector(freshservice, state => {
  if (!state?.results?.length) {
    return [];
  }
  const res = state?.results?.map(item => {
    return {
      email: item.email,
      id: item.id,
      is_jira_admin: item.is_jira_admin,
      url: item.url,
      connected: item?.connected,
      last_sync: formatDate(item?.last_sync),
      name: item?.name
    };
  });
  return res;
});

export const getEmailBackendsSelector = createDraftSafeSelector(emailProfile, state => {
  if (!state?.id) {
    return null;
  }
  const res = {
    from_email: state?.from_email,
    host: state?.host,
    id: state?.id,
    port: state?.port,
    use_ssl: state?.use_ssl,
    use_tls: state?.use_tls,
    username: state?.username,
    last_sync: formatDate(state?.last_sync),
    name: state?.name
  };
  return res;
});

export const getEmailProfileSelector = createDraftSafeSelector(emailProfile, state => {
  if (!state?.id) {
    return null;
  }

  const res = {
    host: state?.host,
    port: state?.port,
    userName: state?.username,
    useTsl: state?.use_tls,
    useSsl: state?.use_ssl,
    formEmail: state?.from_email,
    secret: "",
    name: state?.name
  };

  return res;
});

export const getUserProfilesSelector = createDraftSafeSelector(userProfiles, state => {
  if (!state?.results?.length) {
    return { data: [], total: 0 };
  }
  const res = state?.results?.map(item => {
    return {
      email: item?.email,
      permissions: item?.permissions?.join(", "),
      role: item?.role,
      username: item?.username,
      id: item?.id
    };
  });
  return { data: res, total: state?.count };
});

export const getOneUserProfileSelector = createDraftSafeSelector(oneUserProfile, state => {
  if (!state) {
    return null;
  }
  const res = {
    username: state?.user?.username,
    email: state?.user?.email,
    role: state?.role ? { value: state?.role?.id, label: state?.role?.name } : "",
    first_name: state?.user?.first_name,
    last_name: state?.user?.last_name,
    // permissions: state?.role?.permissions?.map(item => ({ value: item?.id, label: item?.name })),
    labels: state?.labels?.map(item => ({ value: item?.id, label: item?.name })),
    groups: state?.groups?.map(item => ({ value: item?.id, label: item?.name })),
    mfa: state?.mfa ?? false,
    user_id: state?.user?.id
  };
  return res;
});

export const getGroupsListForUserProfileSelect = createDraftSafeSelector(
  groupsForUserProfile,
  state => {
    if (!state?.results?.length) {
      return [];
    }

    const res = state?.results.map(item => {
      return {
        value: item.id,
        label: item.name
      };
    });

    return res;
  }
);

export const labelsTableDataSelector = createDraftSafeSelector(labels, state => {
  if (!state?.results?.length) return [];
  return { data: state.results, total: state?.count };
});

export const getCurrentJiraCloudSelector = createDraftSafeSelector(currentJiraCloud, state => {
  if (!state?.id) {
    return null;
  }

  const res = {
    url: state?.url,
    email: state?.email,
    is_jira_admin: state?.is_jira_admin,
    secret: "",
    name: state?.name
  };

  return res;
});

export const getCurrentConnectWiseSelector = createDraftSafeSelector(currentConnectWise, state => {
  if (!state?.id) {
    return null;
  }

  const res = {
    url: state?.url,
    company_id: state?.company_id,
    public_key: state?.public_key,
    private_key: "",
    client_id: "",
    name: state?.name
  };

  return res;
});

export const getCurrentJiraServerSelector = createDraftSafeSelector(currentJiraServer, state => {
  if (!state?.id) {
    return null;
  }

  const res = {
    url: state?.url,
    username: state?.username,
    secret: "",
    name: state?.name
  };

  return res;
});

export const getCurrentFreshserviceSelector = createDraftSafeSelector(
  currentFreshservice,
  state => {
    if (!state?.id) {
      return null;
    }

    const res = {
      company_domain: state?.company_domain,
      email: state?.email,
      secret: "",
      name: state?.name
    };

    return res;
  }
);

export const getTemplateListMiniSelect = createDraftSafeSelector(templateListMini, state => {
  if (!state?.results?.length) {
    return [];
  }

  const res = state?.results.map(item => {
    return {
      value: item.id,
      label: item.name
    };
  });

  return res;
});

export const createList = arr => {
  if (arr?.length) {
    return arr?.map(item => ({ value: item?.id, label: item?.name }));
  }
  return [];
};

export const jiraAllSelect = createDraftSafeSelector(jiraAll, state => {
  const res = {
    issue_types: createList(state?.issue_types),
    priorities: createList(state?.priorities),
    projects: createList(state?.projects)
  };

  return res;
});

export const freshserviceConfigSelect = createDraftSafeSelector(freshserviceConfig, state => {
  if (state?.issue_types) {
    const res = {
      issue_types: Object.entries(state?.issue_types).map(([key, val]) => {
        return { value: key, label: val };
      }),
      priorities: Object.entries(state?.priorities).map(([key, val]) => {
        return { value: key, label: val };
      }),
      projects: []
    };
    return res;
  }
  return {
    issue_types: [],
    priorities: [],
    projects: []
  };
});

export const connectWiseConfigSelect = createDraftSafeSelector(connectWiseConfig, state => {
  if (state?.priorities) {
    return {
      priorities: Object.entries(state?.priorities).map(([key, val]) => {
        return { value: key, label: val };
      }),
      projects: Object.entries(state?.companies).map(([key, val]) => {
        return { value: key, label: val };
      }),
      issue_types: []
    };
  }
  return {
    issue_types: [],
    priorities: [],
    projects: []
  };
});

export const statusSelect = createDraftSafeSelector(status, state => {
  if (!state?.results) {
    return [];
  }
  const res = Object.entries(state?.results).map(([key, val]) => {
    return { value: key, label: val };
  });
  return res;
});

export const getTicketsConfigListSelector = createDraftSafeSelector(
  ticketsConfigList,
  (_, type) => type,
  jiraAllSelect,
  freshserviceConfigSelect,
  connectWiseConfigSelect,
  (state, type, jiraAllList, freshserviceConfigList, connectWiseConfigList) => {
    if (!state?.results?.length) {
      return [];
    }
    let lists = "";

    switch (type) {
      case "jiraprofile":
        lists = jiraAllList;
        break;
      case "jiracloudprofile":
        lists = jiraAllList;
        break;
      case "freshserviceprofile":
        lists = freshserviceConfigList;
        break;
      case "connectwiseprofile":
        lists = connectWiseConfigList;
        break;
      default:
        break;
    }

    const res = state?.results?.map(item => {
      return {
        name: item?.name,
        id: item?.id,
        project: lists?.projects?.find(it => it?.value === item?.project)?.label || "",
        issue_type: lists?.issue_types?.find(it => it?.value === item?.issue_type)?.label || ""
      };
    });

    return res;
  }
);

export const getTicketConfigSelector = createDraftSafeSelector(
  ticketsConfig,
  (_, type) => type,
  getTemplateListMiniSelect,
  jiraAllSelect,
  freshserviceConfigSelect,
  connectWiseConfigSelect,
  statusSelect,
  (
    state,
    type,
    templateList,
    jiraAllList,
    freshserviceConfigList,
    connectWiseConfigList,
    statusList
  ) => {
    let lists = "";

    switch (type) {
      case "jiraprofile":
        lists = jiraAllList;
        break;
      case "jiracloudprofile":
        lists = jiraAllList;
        break;
      case "freshserviceprofile":
        lists = freshserviceConfigList;
        break;
      case "connectwiseprofile":
        lists = connectWiseConfigList;
        break;
      default:
        break;
    }
    if (!state?.id) {
      return null;
    }
    const res = {
      name: state?.name,
      project: lists?.projects?.find(item => item.value === state?.project),
      issue_type: lists?.issue_types?.find(item => item.value === state?.issue_type),
      template: templateList?.find(item => item.value === state?.template),
      priorities: {
        Unknown: lists?.priorities?.find(
          item =>
            item.value === state?.priorities?.UNKNOWN || item.value === state?.priorities?.Unknown
        ),
        Informational: lists?.priorities?.find(
          item =>
            item.value === state?.priorities?.INFORMATIONAL ||
            item.value === state?.priorities?.Informational
        ),
        Low: lists?.priorities?.find(
          item => item.value === state?.priorities?.LOW || item.value === state?.priorities?.Low
        ),
        Medium: lists?.priorities?.find(
          item =>
            item.value === state?.priorities?.MEDIUM || item.value === state?.priorities?.Medium
        ),
        High: lists?.priorities?.find(
          item => item.value === state?.priorities?.HIGH || item.value === state?.priorities?.High
        ),
        Critical: lists?.priorities?.find(
          item =>
            item.value === state?.priorities?.CRITICAL || item.value === state?.priorities?.Critical
        )
      },
      ticket_profile_type: state?.ticket_profile_type,
      auto_maintain_tickets: state?.auto_maintain_tickets,
      update_closed_tickets: state?.update_closed_tickets,
      commentAnalysisList: state?.comment_analysis?.map(item => {
        return {
          regex: item?.regex,
          status: statusList?.find(it => it?.value === item?.status),
          id: item?.id
        };
      })
    };
    return res;
  }
);
