/* eslint-disable camelcase */
import { API, authorization, authorizationTenant, contentTypeJson } from "api/api";
import { get, post, patch, remove, put } from "api/base";

export const getTemplates = fields => {
  const { page, pageSize, ordering } = fields;
  return get(
    `${API}/tickets-template?page=${page + 1 || 1}&page_size=${pageSize}&ordering=${ordering}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getTemplate = ({ id }) => {
  return get(`${API}/tickets-template/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addTemplate = fields => {
  return post(`${API}/tickets-template`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editTemplate = fields => {
  const { id, data } = fields;

  return patch(`${API}/tickets-template/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteTemplate = id => {
  return remove(
    `${API}/tickets-template/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const duplicateTemplate = id => {
  return get(`${API}/tickets-template/${id}/duplicate`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTemplatesDataTypes = () => {
  return get(`${API}/tickets-template/data-types`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getRoles = fields => {
  const { page, pageSize, ordering, search } = fields;
  const order = ordering ? `&ordering=${ordering}` : "";
  return get(
    `${API}/roles?page=${page + 1 || 1}&page_size=${pageSize}${order}&search=${search || ""}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getRole = ({ id }) => {
  return get(`${API}/roles/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addRole = fields => {
  return post(`${API}/roles`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editRole = fields => {
  const { id, data } = fields;
  return put(`${API}/roles/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteRole = id => {
  return remove(
    `${API}/roles/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getUsers = () => {
  return get(`${API}/user-profiles`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getPermissions = () => {
  return get(`${API}/permissions`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getS3Profile = () => {
  return get(`${API}/s3-profile`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

// Jira Server
export const getJiraServer = () => {
  return get(`${API}/jira-profile`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getOneJiraServer = ({ id }) => {
  return get(`${API}/jira-profile/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addJiraServer = fields => {
  return post(`${API}/jira-profile`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editJiraServer = fields => {
  const { id, data } = fields;

  return patch(`${API}/jira-profile/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteJiraServer = ({ id }) => {
  return remove(
    `${API}/jira-profile/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

// // Jira Cloud

export const getJiraCloud = () => {
  return get(`${API}/jira-cloud-profile`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getOneJiraCloud = ({ id }) => {
  return get(`${API}/jira-cloud-profile/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addJiraCloud = fields => {
  return post(`${API}/jira-cloud-profile`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editJiraCloud = fields => {
  const { id, data } = fields;

  return patch(`${API}/jira-cloud-profile/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteJiraCloud = ({ id }) => {
  return remove(
    `${API}/jira-cloud-profile/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

// // Jira Cloud
export const getFreshservice = () => {
  return get(`${API}/fresh-service`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getOneFreshservice = ({ id }) => {
  return get(`${API}/fresh-service/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addFreshservice = fields => {
  return post(`${API}/fresh-service`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editFreshservice = fields => {
  const { id, data } = fields;

  return patch(`${API}/fresh-service/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteFreshservice = ({ id }) => {
  return remove(
    `${API}/fresh-service/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

// // Connect Wise

export const getConnectWise = () => {
  return get(`${API}/connectwise`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getOneConnectWise = ({ id }) => {
  return get(`${API}/connectwise/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addConnectWise = fields => {
  return post(`${API}/connectwise`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editConnectWise = fields => {
  const { id, data } = fields;

  return patch(`${API}/connectwise/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteConnectWise = ({ id }) => {
  return remove(
    `${API}/connectwise/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

// // Email Backends
export const getEmailProfile = () => {
  return get(`${API}/email-profile`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addEmailProfile = fields => {
  return post(`${API}/email-profile`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editEmailProfile = fields => {
  return patch(`${API}/email-profile/`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteEmailProfile = () => {
  return remove(
    `${API}/email-profile/`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

// S3
export const addS3Profile = fields => {
  return post(`${API}/s3-profile`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editS3Profile = fields => {
  return patch(`${API}/s3-profile/`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteS3Profile = () => {
  return remove(
    `${API}/s3-profile/`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

// User Profiles
export const getUserProfiles = fields => {
  const { page, pageSize, ordering, search } = fields;
  return get(
    `${API}/user-profiles?page=${page + 1 ||
      1}&page_size=${pageSize}&ordering=${ordering}&search=${search}`,
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getOneUserProfile = ({ id }) => {
  return get(`${API}/user-profiles/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addUserProfiles = fields => {
  return post(`${API}/user-profiles`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editUserProfiles = fields => {
  const { id, data } = fields;
  return put(`${API}/user-profiles/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteUserProfiles = ({ id }) => {
  return remove(
    `${API}/user-profiles/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getUserProfilesGroup = () => {
  return get(`${API}/groups-mini?page_size=1000`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

// Labels
export const getLabels = fields => {
  const { page, pageSize, ordering, search } = fields;
  let url = `${API}/labels?page=${page + 1}&page_size=${pageSize}`;

  if (ordering) {
    url += `&ordering=${ordering}`;
  }
  if (search) {
    url += `&search=${search}`;
  }

  return get(url, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getLabel = ({ id }) => {
  return get(`${API}/labels/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addLabel = fields => {
  return post(`${API}/labels`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editLabel = fields => {
  const { id, data } = fields;
  return patch(`${API}/labels/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};
export const deleteSettingsLabel = id => {
  return remove(
    `${API}/labels/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getTicketsConfig = fields => {
  const { id } = fields;
  return get(`${API}/tickets-config?ticket_profile_id=${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTicketConfig = ({ id }) => {
  return get(`${API}/tickets-config/${id}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const addTicketConfig = fields => {
  return post(`${API}/tickets-config`, JSON.stringify(fields), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const editTicketConfig = fields => {
  const { id, data } = fields;
  return put(`${API}/tickets-config/${id}`, JSON.stringify(data), {
    ...authorization(),
    ...contentTypeJson(),
    ...authorizationTenant()
  });
};

export const deleteTicketConfig = ({ id }) => {
  return remove(
    `${API}/tickets-config/${id}`,
    {},
    {
      ...authorization(),
      ...authorizationTenant()
    }
  );
};

export const getJiraAll = ({ id, type }) => {
  return get(`${API}/jira/all?object_id=${id}&model=${type}`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getFreshserviceConfig = ({ id }) => {
  return get(`${API}/fresh-service/${id}/config-data`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getConnectWiseConfig = ({ id }) => {
  return get(`${API}/connectwise/${id}/config-data`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getTemplateListMini = () => {
  return get(`${API}/tickets-template-mini?page_size=1000`, {
    ...authorization(),
    ...authorizationTenant()
  });
};

export const getStatusForTicketConfig = () => {
  return get(`${API}/findings/filter-options?field=status`, {
    ...authorization(),
    ...authorizationTenant()
  });
};
