import React, { useCallback, useState } from "react";
import { Button, ContentContainer, Select } from "components/simple";
import Stepper from "components/simple/Stepper";

import styles from "./styles.module.scss";
import { Divider } from "@mui/material";
import { TabPanel } from "components/simple/Tabs/Tabs";
import { useHistory } from "react-router-dom";
import { routes } from "router";
import AzureForm from "./AzureForm";
import GoogleCloudForm from "./GoogleCloudForm";
import { AccessKeys, ManualSetup } from "./AWSForm";

const steps = ["Cloud Account Details", "Set Up Connectivity"];
const cloudTypeOptions = [
  {
    value: "Microsoft Azure",
    label: "Microsoft Azure"
  },
  {
    value: "Google Cloud Platform (GCP)",
    label: "Google Cloud Platform (GCP)"
  },
  {
    value: "Amazon Web Service (AWS)",
    label: "Amazon Web Service (AWS)"
  }
];
const connectionMethodOptions = [
  {
    value: "Manual Setup",
    label: "Manual Setup"
  },
  {
    value: "Access Keys",
    label: "Access Keys"
  }
];

const AddAccount = () => {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [cloudAccountType, setAccountType] = useState(null);
  const [connectionMethod, setConnectionMethod] = useState(null);

  const isNextDisabled =
    !cloudAccountType ||
    (cloudAccountType?.value === "Amazon Web Service (AWS)" && !connectionMethod);

  const selectAccountType = v => {
    setAccountType(v);
  };

  const selectConnectionMethod = v => {
    setConnectionMethod(v);
  };

  const onCancelHandler = useCallback(() => {
    history.push(routes.cloudAccounts);
  }, []);

  const onNextHandler = () => {
    if (cloudAccountType) setActiveStep(state => state + 1);
  };

  const onBackHandler = useCallback(() => {
    setActiveStep(state => state - 1);
  }, [setActiveStep]);

  const renderForm = () => {
    if (!cloudAccountType) return null;
    if (cloudAccountType.value === "Amazon Web Service (AWS)" && !connectionMethod) return null;

    switch (cloudAccountType.value) {
      case "Microsoft Azure":
        return <AzureForm onBackHandler={onBackHandler} onCancelHandler={onCancelHandler} />;
      case "Google Cloud Platform (GCP)":
        return <GoogleCloudForm onBackHandler={onBackHandler} onCancelHandler={onCancelHandler} />;
      case "Amazon Web Service (AWS)":
        if (connectionMethod.value === "Manual Setup") {
          return <ManualSetup onBackHandler={onBackHandler} onCancelHandler={onCancelHandler} />;
        }
        if (connectionMethod.value === "Access Keys") {
          return <AccessKeys onBackHandler={onBackHandler} onCancelHandler={onCancelHandler} />;
        }
        return null;
      default:
        return null;
    }
  };

  return (
    <ContentContainer className={styles.container}>
      <div className={styles.stepperWrapper}>
        <Stepper steps={steps} activeStep={activeStep} className={styles.stepper} />
      </div>
      <Divider />
      <TabPanel value={activeStep} index={0}>
        <div className={styles.tabPanel}>
          <div className={styles.selectTypePage}>
            <Select
              label="Cloud Account Type"
              placeholder="Select the Cloud Account Type"
              options={cloudTypeOptions}
              value={cloudAccountType}
              onChange={selectAccountType}
            />
            {cloudAccountType?.value === "Amazon Web Service (AWS)" && (
              <Select
                label="Connection Method"
                placeholder="Select the Connection Method"
                options={connectionMethodOptions}
                value={connectionMethod}
                onChange={selectConnectionMethod}
              />
            )}
            <div className={styles.btnContainer}>
              <Button variant="outline" onClick={onCancelHandler}>
                Cancel
              </Button>
              <Button disabled={isNextDisabled} onClick={onNextHandler}>
                Next
              </Button>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={activeStep} index={1} style={{ width: "100%" }}>
        <div className={styles.tabPanel}>{renderForm()}</div>
      </TabPanel>
    </ContentContainer>
  );
};

export default AddAccount;
